/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VideoSourceFromJSON, VideoSourceToJSON, } from "./VideoSource";
/**
 * Check if a given object implements the Video interface.
 */
export function instanceOfVideo(value) {
    if (!("source" in value) || value.source === undefined)
        return false;
    if (!("id" in value) || value.id === undefined)
        return false;
    if (!("title" in value) || value.title === undefined)
        return false;
    if (!("description" in value) || value.description === undefined)
        return false;
    if (!("channelId" in value) || value.channelId === undefined)
        return false;
    if (!("channelTitle" in value) || value.channelTitle === undefined)
        return false;
    if (!("publishedAt" in value) || value.publishedAt === undefined)
        return false;
    return true;
}
export function VideoFromJSON(json) {
    return VideoFromJSONTyped(json, false);
}
export function VideoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        source: VideoSourceFromJSON(json.source),
        id: json.id,
        title: json.title,
        description: json.description,
        channelId: json.channelId,
        channelTitle: json.channelTitle,
        publishedAt: new Date(json.publishedAt),
        thumbnailUrl: json.thumbnailUrl == null ? undefined : json.thumbnailUrl,
    };
}
export function VideoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        source: VideoSourceToJSON(value.source),
        id: value.id,
        title: value.title,
        description: value.description,
        channelId: value.channelId,
        channelTitle: value.channelTitle,
        publishedAt: value.publishedAt.toISOString(),
        thumbnailUrl: value.thumbnailUrl,
    };
}
