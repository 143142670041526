// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file gateway/v1/workout_videos.proto (package gateway.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ListVideosRequest, ListVideosResponse } from "./workout_videos_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service gateway.v1.WorkoutVideosGrpc
 */
export const WorkoutVideosGrpc = {
  typeName: "gateway.v1.WorkoutVideosGrpc",
  methods: {
    /**
     * Combines video.v1.ListVideos & activity.v1.ListActivities.
     *
     * @generated from rpc gateway.v1.WorkoutVideosGrpc.ListVideos
     */
    listVideos: {
      name: "ListVideos",
      I: ListVideosRequest,
      O: ListVideosResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

