/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the PhotoDownload interface.
 */
export function instanceOfPhotoDownload(value) {
    if (!("dateCreated" in value) || value.dateCreated === undefined)
        return false;
    if (!("id" in value) || value.id === undefined)
        return false;
    if (!("contentUrl" in value) || value.contentUrl === undefined)
        return false;
    return true;
}
export function PhotoDownloadFromJSON(json) {
    return PhotoDownloadFromJSONTyped(json, false);
}
export function PhotoDownloadFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        dateCreated: new Date(json.dateCreated),
        id: json.id,
        contentUrl: json.contentUrl,
    };
}
export function PhotoDownloadToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        dateCreated: value.dateCreated.toISOString(),
        id: value.id,
        contentUrl: value.contentUrl,
    };
}
