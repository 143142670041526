/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Operation1PhotoDownloadFromJSON, } from "./Operation1PhotoDownload";
/**
 * Check if a given object implements the JsonPatchDocument1PhotoDownload interface.
 */
export function instanceOfJsonPatchDocument1PhotoDownload(value) {
    return true;
}
export function JsonPatchDocument1PhotoDownloadFromJSON(json) {
    return JsonPatchDocument1PhotoDownloadFromJSONTyped(json, false);
}
export function JsonPatchDocument1PhotoDownloadFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        operations: json.operations == null
            ? undefined
            : json.operations.map(Operation1PhotoDownloadFromJSON),
        contractResolver: json.contractResolver == null ? undefined : json.contractResolver,
    };
}
export function JsonPatchDocument1PhotoDownloadToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        contractResolver: value.contractResolver,
    };
}
