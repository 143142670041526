// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file activity/v1/activity_tag.proto (package activity.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum activity.v1.ActivityTag
 */
export enum ActivityTag {
  /**
   * @generated from enum value: ACTIVITY_TAG_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ACTIVITY_TAG_CALISTHENICS = 1;
   */
  CALISTHENICS = 1,

  /**
   * @generated from enum value: ACTIVITY_TAG_BODYBUILDING = 2;
   */
  BODYBUILDING = 2,

  /**
   * @generated from enum value: ACTIVITY_TAG_STRENGTH = 3;
   */
  STRENGTH = 3,

  /**
   * @generated from enum value: ACTIVITY_TAG_ENDURANCE = 4;
   */
  ENDURANCE = 4,

  /**
   * @generated from enum value: ACTIVITY_TAG_STRENGTH_ENDURANCE = 5;
   */
  STRENGTH_ENDURANCE = 5,

  /**
   * @generated from enum value: ACTIVITY_TAG_ISOMETRIC = 6;
   */
  ISOMETRIC = 6,

  /**
   * @generated from enum value: ACTIVITY_TAG_ISOTONIC = 7;
   */
  ISOTONIC = 7,

  /**
   * @generated from enum value: ACTIVITY_TAG_CARDIO = 8;
   */
  CARDIO = 8,

  /**
   * @generated from enum value: ACTIVITY_TAG_SPORT = 9;
   */
  SPORT = 9,

  /**
   * @generated from enum value: ACTIVITY_TAG_OTHER = 10;
   */
  OTHER = 10,

  /**
   * @generated from enum value: ACTIVITY_TAG_YOGA = 11;
   */
  YOGA = 11,

  /**
   * @generated from enum value: ACTIVITY_TAG_BODYSHAPING = 12;
   */
  BODYSHAPING = 12,

  /**
   * @generated from enum value: ACTIVITY_TAG_PLYOMETRIC = 13;
   */
  PLYOMETRIC = 13,

  /**
   * @generated from enum value: ACTIVITY_TAG_STRETCHING = 14;
   */
  STRETCHING = 14,

  /**
   * @generated from enum value: ACTIVITY_TAG_BALANCE = 15;
   */
  BALANCE = 15,

  /**
   * @generated from enum value: ACTIVITY_TAG_STABILITY = 16;
   */
  STABILITY = 16,

  /**
   * @generated from enum value: ACTIVITY_TAG_MOBILITY = 17;
   */
  MOBILITY = 17,

  /**
   * @generated from enum value: ACTIVITY_TAG_FASCIA = 18;
   */
  FASCIA = 18,

  /**
   * @generated from enum value: ACTIVITY_TAG_POWERLIFTING = 19;
   */
  POWERLIFTING = 19,

  /**
   * @generated from enum value: ACTIVITY_TAG_STRONGMAN = 20;
   */
  STRONGMAN = 20,

  /**
   * @generated from enum value: ACTIVITY_TAG_WEIGHTLIFTING = 21;
   */
  WEIGHTLIFTING = 21,

  /**
   * @generated from enum value: ACTIVITY_TAG_PUSH_UP_VARIATIONS = 22;
   */
  PUSH_UP_VARIATIONS = 22,

  /**
   * @generated from enum value: ACTIVITY_TAG_CORE = 23;
   */
  CORE = 23,

  /**
   * @generated from enum value: ACTIVITY_TAG_FULL_BODY = 24;
   */
  FULL_BODY = 24,

  /**
   * @generated from enum value: ACTIVITY_TAG_BEGINNER = 25;
   */
  BEGINNER = 25,

  /**
   * @generated from enum value: ACTIVITY_TAG_INTERMEDIATE = 26;
   */
  INTERMEDIATE = 26,

  /**
   * @generated from enum value: ACTIVITY_TAG_ADVANCED = 27;
   */
  ADVANCED = 27,
}
// Retrieve enum metadata with: proto3.getEnumType(ActivityTag)
proto3.util.setEnumType(ActivityTag, "activity.v1.ActivityTag", [
  { no: 0, name: "ACTIVITY_TAG_UNSPECIFIED" },
  { no: 1, name: "ACTIVITY_TAG_CALISTHENICS" },
  { no: 2, name: "ACTIVITY_TAG_BODYBUILDING" },
  { no: 3, name: "ACTIVITY_TAG_STRENGTH" },
  { no: 4, name: "ACTIVITY_TAG_ENDURANCE" },
  { no: 5, name: "ACTIVITY_TAG_STRENGTH_ENDURANCE" },
  { no: 6, name: "ACTIVITY_TAG_ISOMETRIC" },
  { no: 7, name: "ACTIVITY_TAG_ISOTONIC" },
  { no: 8, name: "ACTIVITY_TAG_CARDIO" },
  { no: 9, name: "ACTIVITY_TAG_SPORT" },
  { no: 10, name: "ACTIVITY_TAG_OTHER" },
  { no: 11, name: "ACTIVITY_TAG_YOGA" },
  { no: 12, name: "ACTIVITY_TAG_BODYSHAPING" },
  { no: 13, name: "ACTIVITY_TAG_PLYOMETRIC" },
  { no: 14, name: "ACTIVITY_TAG_STRETCHING" },
  { no: 15, name: "ACTIVITY_TAG_BALANCE" },
  { no: 16, name: "ACTIVITY_TAG_STABILITY" },
  { no: 17, name: "ACTIVITY_TAG_MOBILITY" },
  { no: 18, name: "ACTIVITY_TAG_FASCIA" },
  { no: 19, name: "ACTIVITY_TAG_POWERLIFTING" },
  { no: 20, name: "ACTIVITY_TAG_STRONGMAN" },
  { no: 21, name: "ACTIVITY_TAG_WEIGHTLIFTING" },
  { no: 22, name: "ACTIVITY_TAG_PUSH_UP_VARIATIONS" },
  { no: 23, name: "ACTIVITY_TAG_CORE" },
  { no: 24, name: "ACTIVITY_TAG_FULL_BODY" },
  { no: 25, name: "ACTIVITY_TAG_BEGINNER" },
  { no: 26, name: "ACTIVITY_TAG_INTERMEDIATE" },
  { no: 27, name: "ACTIVITY_TAG_ADVANCED" },
]);

