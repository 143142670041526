import { pipe } from "fp-ts/es6/function";
import * as Opt from "fp-ts/es6/Option";
import * as Ord from "fp-ts/es6/Ord";
import { Lens, Prism } from "monocle-ts";
import { ioTsPrism } from "../internal";
import { Temporal } from "@js-temporal/polyfill";
import * as t from "io-ts";
export const mkDuration = (hours, minutes, seconds) => Temporal.Duration.from({
    hours,
    minutes,
    // Fractional seconds aren't supported
    microseconds: seconds * 1000000,
});
export const _hours = new Lens((s) => s.hours, (hours) => (s) => s.with({ hours }));
export const _minutes = new Lens((s) => s.minutes, (minutes) => (s) => s.with({ minutes }));
export const _seconds = new Lens((s) => s.seconds, (seconds) => (s) => s.with({ seconds }));
export const duration = {
    ...Ord.fromCompare((a, b) => Temporal.Duration.compare(a, b)),
    empty: mkDuration(0, 0, 0),
    concat: (a, b) => a.add(b),
    inverse: (a) => a.negated(),
    add: (t, d) => t.add(d),
    difference: (t, t2) => t.until(t2),
};
export const equals = (d2) => (d) => duration.equals(d, d2);
export const compare = (d2) => (d) => duration.compare(d, d2);
export const concat = (d2) => (d) => duration.concat(d, d2);
export const empty = duration.empty;
export const inverse = duration.inverse;
export const add = (d) => (t) => duration.add(t, d);
export const difference = (t2) => (t) => duration.difference(t, t2);
export const isoStringAsDuration = new Prism((s) => pipe(Opt.tryCatch(() => Temporal.Duration.from(s)), Opt.filter((d) => d.years === 0 && d.months === 0 && d.weeks === 0 && d.days === 0)), (s) => s.toString());
export const durationT = t.string.pipe(ioTsPrism("Duration", (a) => a instanceof Temporal.Duration &&
    [a.years, a.months, a.weeks, a.days].every((a) => a === 0), isoStringAsDuration));
export const MonoidSum = {
    empty,
    concat: (x, y) => concat(x)(y),
};
