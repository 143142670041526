/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ActivitySettings interface.
 */
export function instanceOfActivitySettings(value) {
    if (!("isLiked" in value) || value.isLiked === undefined)
        return false;
    if (!("isDenylisted" in value) || value.isDenylisted === undefined)
        return false;
    return true;
}
export function ActivitySettingsFromJSON(json) {
    return ActivitySettingsFromJSONTyped(json, false);
}
export function ActivitySettingsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        isLiked: json.isLiked,
        isDenylisted: json.isDenylisted,
    };
}
export function ActivitySettingsToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        isLiked: value.isLiked,
        isDenylisted: value.isDenylisted,
    };
}
