/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const ActivityType = {
    Exercise: "exercise",
    Muscles: "muscles",
    Job: "job",
    Sleep: "sleep",
    Group: "group",
};
export function instanceOfActivityType(value) {
    for (const key in ActivityType) {
        if (Object.prototype.hasOwnProperty.call(ActivityType, key)) {
            if (ActivityType[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function ActivityTypeFromJSON(json) {
    return ActivityTypeFromJSONTyped(json, false);
}
export function ActivityTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ActivityTypeToJSON(value) {
    return value;
}
