/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ModelSet interface.
 */
export function instanceOfModelSet(value) {
    return true;
}
export function ModelSetFromJSON(json) {
    return ModelSetFromJSONTyped(json, false);
}
export function ModelSetFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        repetitions: json.repetitions == null ? undefined : json.repetitions,
        weight: json.weight == null ? undefined : json.weight,
    };
}
export function ModelSetToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        repetitions: value.repetitions,
        weight: value.weight,
    };
}
