/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityInstanceFromJSON, ActivityInstanceFromJSONTyped, ActivityInstanceToJSON, } from "./ActivityInstance";
import { ActivityTypeFromJSON, ActivityTypeToJSON, } from "./ActivityType";
/**
 * Check if a given object implements the ActivityInstanceGroup interface.
 */
export function instanceOfActivityInstanceGroup(value) {
    if (!("activities" in value) || value.activities === undefined)
        return false;
    if (!("$type" in value) || value.$type === undefined)
        return false;
    if (!("repetitions" in value) || value.repetitions === undefined)
        return false;
    return true;
}
export function ActivityInstanceGroupFromJSON(json) {
    return ActivityInstanceGroupFromJSONTyped(json, false);
}
export function ActivityInstanceGroupFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        ...ActivityInstanceFromJSONTyped(json, ignoreDiscriminator),
        activities: json.activities.map(ActivityInstanceFromJSON),
        $type: ActivityTypeFromJSON(json.$type),
        repetitions: json.repetitions,
        breakBetweenRepetitions: json.breakBetweenRepetitions == null
            ? undefined
            : json.breakBetweenRepetitions,
    };
}
export function ActivityInstanceGroupToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        ...ActivityInstanceToJSON(value, true),
        activities: value.activities.map((a) => ActivityInstanceToJSON(a)),
        $type: ActivityTypeToJSON(value.$type),
        repetitions: value.repetitions,
        breakBetweenRepetitions: value.breakBetweenRepetitions,
    };
}
