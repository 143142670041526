/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserSleepScheduleFromJSON, UserSleepScheduleToJSON, } from "./UserSleepSchedule";
/**
 * Check if a given object implements the UserSleep interface.
 */
export function instanceOfUserSleep(value) {
    if (!("schedule" in value) || value.schedule === undefined)
        return false;
    return true;
}
export function UserSleepFromJSON(json) {
    return UserSleepFromJSONTyped(json, false);
}
export function UserSleepFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        schedule: UserSleepScheduleFromJSON(json.schedule),
    };
}
export function UserSleepToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        schedule: UserSleepScheduleToJSON(value.schedule),
    };
}
