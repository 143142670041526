// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file activity/v1/activity.proto (package activity.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, StringValue } from "@bufbuild/protobuf";
import { ActivityCategory } from "./activity_category_pb.js";
import { ActivityTag } from "./activity_tag_pb.js";
import { MuscleShare } from "./muscle_share_pb.js";
import { Equipment } from "./equipment_pb.js";
import { ActivitySettings } from "./activity_settings_pb.js";
import { ActivityStatsSummary } from "./activity_stats_summary_pb.js";

/**
 * @generated from message activity.v1.ActivityBase
 */
export class ActivityBase extends Message<ActivityBase> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.StringValue created_by = 2;
   */
  createdBy?: string;

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: string instructions = 4;
   */
  instructions = "";

  constructor(data?: PartialMessage<ActivityBase>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "activity.v1.ActivityBase";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_by", kind: "message", T: StringValue },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "instructions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivityBase {
    return new ActivityBase().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivityBase {
    return new ActivityBase().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivityBase {
    return new ActivityBase().fromJsonString(jsonString, options);
  }

  static equals(a: ActivityBase | PlainMessage<ActivityBase> | undefined, b: ActivityBase | PlainMessage<ActivityBase> | undefined): boolean {
    return proto3.util.equals(ActivityBase, a, b);
  }
}

/**
 * @generated from message activity.v1.Activity
 */
export class Activity extends Message<Activity> {
  /**
   * @generated from oneof activity.v1.Activity.kind
   */
  kind: {
    /**
     * @generated from field: activity.v1.ActivityExercise exercise = 1;
     */
    value: ActivityExercise;
    case: "exercise";
  } | {
    /**
     * @generated from field: activity.v1.ActivityMuscles muscles = 2;
     */
    value: ActivityMuscles;
    case: "muscles";
  } | {
    /**
     * @generated from field: activity.v1.ActivityJob job = 3;
     */
    value: ActivityJob;
    case: "job";
  } | {
    /**
     * @generated from field: activity.v1.ActivitySleep sleep = 4;
     */
    value: ActivitySleep;
    case: "sleep";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Activity>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "activity.v1.Activity";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "exercise", kind: "message", T: ActivityExercise, oneof: "kind" },
    { no: 2, name: "muscles", kind: "message", T: ActivityMuscles, oneof: "kind" },
    { no: 3, name: "job", kind: "message", T: ActivityJob, oneof: "kind" },
    { no: 4, name: "sleep", kind: "message", T: ActivitySleep, oneof: "kind" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Activity {
    return new Activity().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Activity {
    return new Activity().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Activity {
    return new Activity().fromJsonString(jsonString, options);
  }

  static equals(a: Activity | PlainMessage<Activity> | undefined, b: Activity | PlainMessage<Activity> | undefined): boolean {
    return proto3.util.equals(Activity, a, b);
  }
}

/**
 * @generated from message activity.v1.ActivityExercise
 */
export class ActivityExercise extends Message<ActivityExercise> {
  /**
   * @generated from field: activity.v1.ActivityBase base = 1;
   */
  base?: ActivityBase;

  /**
   * @generated from field: activity.v1.ActivityCategory category = 2;
   */
  category = ActivityCategory.UNSPECIFIED;

  /**
   * @generated from field: repeated activity.v1.ActivityTag tags = 3;
   */
  tags: ActivityTag[] = [];

  /**
   * @generated from field: repeated activity.v1.MuscleShare muscle_shares = 4;
   */
  muscleShares: MuscleShare[] = [];

  /**
   * @generated from field: repeated activity.v1.Equipment equipments = 5;
   */
  equipments: Equipment[] = [];

  /**
   * @generated from field: activity.v1.ActivitySettings settings = 6;
   */
  settings?: ActivitySettings;

  /**
   * @generated from field: activity.v1.ActivityStatsSummary stats = 7;
   */
  stats?: ActivityStatsSummary;

  constructor(data?: PartialMessage<ActivityExercise>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "activity.v1.ActivityExercise";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base", kind: "message", T: ActivityBase },
    { no: 2, name: "category", kind: "enum", T: proto3.getEnumType(ActivityCategory) },
    { no: 3, name: "tags", kind: "enum", T: proto3.getEnumType(ActivityTag), repeated: true },
    { no: 4, name: "muscle_shares", kind: "message", T: MuscleShare, repeated: true },
    { no: 5, name: "equipments", kind: "message", T: Equipment, repeated: true },
    { no: 6, name: "settings", kind: "message", T: ActivitySettings },
    { no: 7, name: "stats", kind: "message", T: ActivityStatsSummary },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivityExercise {
    return new ActivityExercise().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivityExercise {
    return new ActivityExercise().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivityExercise {
    return new ActivityExercise().fromJsonString(jsonString, options);
  }

  static equals(a: ActivityExercise | PlainMessage<ActivityExercise> | undefined, b: ActivityExercise | PlainMessage<ActivityExercise> | undefined): boolean {
    return proto3.util.equals(ActivityExercise, a, b);
  }
}

/**
 * @generated from message activity.v1.ActivityMuscles
 */
export class ActivityMuscles extends Message<ActivityMuscles> {
  /**
   * @generated from field: activity.v1.ActivityBase base = 1;
   */
  base?: ActivityBase;

  constructor(data?: PartialMessage<ActivityMuscles>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "activity.v1.ActivityMuscles";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base", kind: "message", T: ActivityBase },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivityMuscles {
    return new ActivityMuscles().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivityMuscles {
    return new ActivityMuscles().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivityMuscles {
    return new ActivityMuscles().fromJsonString(jsonString, options);
  }

  static equals(a: ActivityMuscles | PlainMessage<ActivityMuscles> | undefined, b: ActivityMuscles | PlainMessage<ActivityMuscles> | undefined): boolean {
    return proto3.util.equals(ActivityMuscles, a, b);
  }
}

/**
 * @generated from message activity.v1.ActivityJob
 */
export class ActivityJob extends Message<ActivityJob> {
  /**
   * @generated from field: activity.v1.ActivityBase base = 1;
   */
  base?: ActivityBase;

  /**
   * @generated from field: repeated activity.v1.MuscleShare muscle_shares = 2;
   */
  muscleShares: MuscleShare[] = [];

  constructor(data?: PartialMessage<ActivityJob>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "activity.v1.ActivityJob";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base", kind: "message", T: ActivityBase },
    { no: 2, name: "muscle_shares", kind: "message", T: MuscleShare, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivityJob {
    return new ActivityJob().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivityJob {
    return new ActivityJob().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivityJob {
    return new ActivityJob().fromJsonString(jsonString, options);
  }

  static equals(a: ActivityJob | PlainMessage<ActivityJob> | undefined, b: ActivityJob | PlainMessage<ActivityJob> | undefined): boolean {
    return proto3.util.equals(ActivityJob, a, b);
  }
}

/**
 * @generated from message activity.v1.ActivitySleep
 */
export class ActivitySleep extends Message<ActivitySleep> {
  /**
   * @generated from field: activity.v1.ActivityBase base = 1;
   */
  base?: ActivityBase;

  constructor(data?: PartialMessage<ActivitySleep>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "activity.v1.ActivitySleep";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base", kind: "message", T: ActivityBase },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivitySleep {
    return new ActivitySleep().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivitySleep {
    return new ActivitySleep().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivitySleep {
    return new ActivitySleep().fromJsonString(jsonString, options);
  }

  static equals(a: ActivitySleep | PlainMessage<ActivitySleep> | undefined, b: ActivitySleep | PlainMessage<ActivitySleep> | undefined): boolean {
    return proto3.util.equals(ActivitySleep, a, b);
  }
}

