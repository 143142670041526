/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ExerciseSlot interface.
 */
export function instanceOfExerciseSlot(value) {
    if (!("start" in value) || value.start === undefined)
        return false;
    if (!("end" in value) || value.end === undefined)
        return false;
    return true;
}
export function ExerciseSlotFromJSON(json) {
    return ExerciseSlotFromJSONTyped(json, false);
}
export function ExerciseSlotFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        start: new Date(json.start),
        end: new Date(json.end),
    };
}
export function ExerciseSlotToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        start: value.start.toISOString(),
        end: value.end.toISOString(),
    };
}
