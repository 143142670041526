import { FC, ReactNode, useEffect } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"
import { useInView } from "react-intersection-observer"
import { FormattedMessage } from "react-intl"
import Media from "react-media"
import { connect, ConnectedProps } from "react-redux"
import { Link, Navigate, useParams, useSearchParams } from "react-router-dom"
import { Button, Col, Container, Row } from "reactstrap"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"

import * as Func from "fp-ts/es6/function"
import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import {
  dateAsTime,
  isoStringAsLocalTimeOfDayDuration,
  LocalTimeOfDay,
} from "time-ts/es6"
import { timeAsLocalTimeOfDayInCurrentEnv } from "time-ts/es6/Local/TimeOfDay"
import {
  lengthDistance,
  lengthHeight,
  mass,
  optionLensToOptional,
  unsafeFromSome,
} from "@fitnesspilot/data-common"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { CoachMessage } from "@fitnesspilot/components-common/dist/molecules/CoachMessage/CoachMessage"
import { StickyFooter } from "@fitnesspilot/components-common/dist/molecules/StickyFooter/StickyFooter"
import {
  FormData as UnitFormData,
  UnitFieldset,
} from "@fitnesspilot/components-common/dist/organisms/UnitFieldset/UnitFieldset"
import {
  FormData as SleepFormData,
  SleepFieldset,
} from "@fitnesspilot/components-event/dist/organisms/SleepFieldset/SleepFieldset"
import {
  FormData as WorkFormData,
  WorkFieldsetContainer,
} from "@fitnesspilot/components-event/dist/organisms/WorkFieldset/WorkFieldset"
import {
  BodyCompositionFieldset,
  FormData as BodyCompositionFormData,
} from "@fitnesspilot/components-human-body/dist/organisms/BodyCompositionFieldset/BodyCompositionFieldset"
import {
  FormData as HeightAndMassFormData,
  HeightAndMassFieldset,
} from "@fitnesspilot/components-human-body/dist/organisms/HeightAndMassFieldset/HeightAndMassFieldset"
import {
  FormData as SexAndBirthDateFormData,
  SexAndBirthDateFieldset,
} from "@fitnesspilot/components-human-body/dist/organisms/SexAndBirthDateFieldset/SexAndBirthDateFieldset"
import {
  FormData as RecommendationsFormData,
  RecommendationsFieldset,
} from "@fitnesspilot/components-user/dist/molecules/RecommendationsFieldset/RecommendationsFieldset"
import {
  _enableExerciseRecommendations,
  empty as emptyGeneralSportsSettings,
} from "@fitnesspilot/data-activity/dist/sport/GeneralSportsSettings"
import * as ActivityData from "@fitnesspilot/data-activity/dist/store"
import { SumBodyCompositionTypes } from "@fitnesspilot/data-human-body/dist/bodyComposition"
import * as HumanBody from "@fitnesspilot/data-human-body/dist/humanBody"
import {
  initialState,
  setUnitSelectionLocal,
  submitSetup,
} from "@fitnesspilot/data-user"
import * as UserData from "@fitnesspilot/data-user"

import { LoggedOutTemplate } from "../../templates/LoggedOut/LoggedOut"

import { Dispatch } from "redux"

type ParentState = UserData.ParentState & ActivityData.ParentState
type Action = UserData.Action | ActivityData.Action

const HeaderCoachMessage = styled(CoachMessage)`
  white-space: nowrap;

  .c-iconCoach {
    font-size: 2em;
  }
`

const BodyCoachMessage = styled(CoachMessage)`
  padding-top: 30px;
`

const CoachMessageTitleWrapper = styled.h1`
  text-align: center;

  && {
    color: inherit;
    font-size: 28px;
  }
`

const CenteredRow = styled(Row)`
  align-items: center;
`

const FoldStep = ({
  className,
  step,
  children,
}: {
  className?: string
  step: SetupStep
  children: Record<SetupStep, ReactNode>
}) => <span {...{ className }}>{children[step]}</span>

const WizardContents = styled(FoldStep)`
  display: contents;

  h2 {
    border-bottom: 1px solid #eee;
  }

  & .c-fieldset + .c-fieldset {
    border: 0;
  }
`

const unitSelection = UserData.selectors.state.composeLens(
  UserData.selectors.unitSelection,
)
const body = UserData.selectors.state.composeLens(UserData.selectors.body)
const work = UserData.selectors.state.composeLens(UserData.selectors.work)
const sleep = UserData.selectors.state.composeLens(UserData.selectors.sleep)
const generalSportsSettings = ActivityData.selectors.state.composeLens(
  ActivityData.selectors.generalSportsSettings,
)

export enum SetupStep {
  generic = "generic",
  workAndSleep = "workAndSleep",
  recommendations = "recommendations",
}

type FormData = SexAndBirthDateFormData &
  HeightAndMassFormData &
  BodyCompositionFormData &
  WorkFormData &
  SleepFormData &
  UnitFormData &
  RecommendationsFormData

const mapState = (state: ParentState) => {
  const defaultValues: Partial<FormData> = {
    sex: pipe(
      state,
      body.composeOptional(optionLensToOptional(HumanBody.sex)).getOption,
    ),
    birthDate: pipe(
      state,
      body.composeOptional(optionLensToOptional(HumanBody.birthDate)).getOption,
    ),
    bodyHeight: pipe(
      state,
      body.composeOptional(optionLensToOptional(HumanBody.height)).getOption,
    ),
    bodyMass: pipe(
      state,
      body.composeOptional(optionLensToOptional(HumanBody.mass)).getOption,
    ),
    bodyComposition: pipe(
      state,
      body.composeOptional(optionLensToOptional(HumanBody.bodyComposition))
        .getOption,
      Opt.map((bc) => ({ ...bc, type: SumBodyCompositionTypes.relative })),
    ),
    unitMass: pipe(state, unitSelection.composeLens(mass).get),
    unitLengthHeight: pipe(state, unitSelection.composeLens(lengthHeight).get),
    unitLengthDistance: pipe(
      state,
      unitSelection.composeLens(lengthDistance).get,
    ),
    work: pipe(
      state,
      work.get,
      ({ time, lunchtime: { duration, within }, ...a }) => ({
        time: pipe(
          time,
          Rec.map((a) =>
            pipe(
              a,
              Opt.map(
                ([start, end]) =>
                  [
                    pipe(
                      start,
                      dateAsTime.composePrism(
                        timeAsLocalTimeOfDayInCurrentEnv(),
                      ).reverseGet,
                    ),
                    pipe(
                      end,
                      dateAsTime.composePrism(
                        timeAsLocalTimeOfDayInCurrentEnv(),
                      ).reverseGet,
                    ),
                  ] as [Date, Date],
              ),
            ),
          ),
        ),
        lunchtime: {
          duration: pipe(
            duration,
            isoStringAsLocalTimeOfDayDuration.reverseGet,
          ),
          within: [
            pipe(
              within[0],
              dateAsTime.composePrism(timeAsLocalTimeOfDayInCurrentEnv())
                .reverseGet,
            ),
            pipe(
              within[1],
              dateAsTime.composePrism(timeAsLocalTimeOfDayInCurrentEnv())
                .reverseGet,
            ),
          ] as [Date, Date],
        },
        ...a,
      }),
    ),
    sleep: pipe(state, sleep.get, ({ time, ...a }) => ({
      time: pipe(
        time,
        Rec.map(
          ([start, end]) =>
            [
              pipe(
                start,
                dateAsTime.composePrism(timeAsLocalTimeOfDayInCurrentEnv())
                  .reverseGet,
              ),
              pipe(
                end,
                dateAsTime.composePrism(timeAsLocalTimeOfDayInCurrentEnv())
                  .reverseGet,
              ),
            ] as [Date, Date],
        ),
      ),
      ...a,
    })),
    enableActivityRecommendations: pipe(
      state,
      generalSportsSettings.composeLens(_enableExerciseRecommendations).get,
    ),
  }
  return {
    defaultValues,
  }
}

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, Func.constVoid)

  return {
    setUnitSelectionLocal: flow(setUnitSelectionLocal, dispatch_),
    onSubmit: ({
      unitMass,
      unitLengthHeight,
      unitLengthDistance,
      sex,
      birthDate,
      bodyHeight,
      bodyMass,
      bodyComposition,
      work,
      sleep,
      enableActivityRecommendations,
      enableNutritionRecommendations,
    }: FormData) =>
      pipe(
        Arr.Traversable.traverse(IO.Applicative)(
          [
            submitSetup({
              units: {
                mass: unitMass,
                lengthHeight: unitLengthHeight,
                lengthDistance: unitLengthDistance,
              },
              body: {
                bodyComposition,
                birthDate,
                mass: bodyMass,
                height: bodyHeight,
                sex,
              },
              work: pipe(
                work,
                ({ time, lunchtime: { duration, within }, ...a }) => ({
                  time: pipe(
                    time,
                    Rec.map(
                      Opt.map(
                        ([start, end]) =>
                          [
                            pipe(
                              start,
                              dateAsTime.composePrism(
                                timeAsLocalTimeOfDayInCurrentEnv(),
                              ).getOption,
                              unsafeFromSome,
                            ),
                            pipe(
                              end,
                              dateAsTime.composePrism(
                                timeAsLocalTimeOfDayInCurrentEnv(),
                              ).getOption,
                              unsafeFromSome,
                            ),
                          ] as [LocalTimeOfDay, LocalTimeOfDay],
                      ),
                    ),
                  ),
                  lunchtime: {
                    duration: pipe(
                      duration,
                      isoStringAsLocalTimeOfDayDuration.getOption,
                      unsafeFromSome,
                    ),
                    within: [
                      pipe(
                        within[0],
                        dateAsTime.composePrism(
                          timeAsLocalTimeOfDayInCurrentEnv(),
                        ).getOption,
                        unsafeFromSome,
                      ),
                      pipe(
                        within[1],
                        dateAsTime.composePrism(
                          timeAsLocalTimeOfDayInCurrentEnv(),
                        ).getOption,
                        unsafeFromSome,
                      ),
                    ] as [LocalTimeOfDay, LocalTimeOfDay],
                  },
                  ...a,
                }),
                Opt.some,
              ),
              sleep: pipe(
                sleep,
                ({ time, ...a }) => ({
                  time: pipe(
                    time,
                    Rec.map(
                      ([start, end]) =>
                        [
                          pipe(
                            start,
                            dateAsTime.composePrism(
                              timeAsLocalTimeOfDayInCurrentEnv(),
                            ).getOption,
                            unsafeFromSome,
                          ),
                          pipe(
                            end,
                            dateAsTime.composePrism(
                              timeAsLocalTimeOfDayInCurrentEnv(),
                            ).getOption,
                            unsafeFromSome,
                          ),
                        ] as [LocalTimeOfDay, LocalTimeOfDay],
                    ),
                  ),
                  ...a,
                }),
                Opt.some,
              ),
              habits: initialState.habits,
              generalSportsSettings: {
                ...emptyGeneralSportsSettings,
                enableExerciseRecommendations: enableActivityRecommendations,
              },
              enableNutritionRecommendations: enableNutritionRecommendations,
            }),
          ],
          dispatch_,
        ),
        IO.map(Func.constVoid),
      )(),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type SetupPageContainerProps = {
  id: string
  className?: string
}

export type SetupPageProps = PropsFromRedux & SetupPageContainerProps

const getStepPath = (
  setIndex: (i: number) => number,
  step: SetupStep,
  steps: Array<SetupStep>,
) =>
  pipe(
    steps,
    Arr.findIndex((s) => s === step),
    Opt.map(setIndex),
    Opt.chain((a) => Arr.lookup(a, steps)),
    Opt.map((s) => `/setup/${s}`),
  )

const CoachMessageTitle = ({ step }: { step: SetupStep }) => (
  <FoldStep {...{ step }}>
    {{
      [SetupStep.generic]: (
        <FormattedMessage defaultMessage="Tell me about you and your current shape" />
      ),
      [SetupStep.workAndSleep]: (
        <FormattedMessage defaultMessage="Tell me about your job and lifestyle" />
      ),
      [SetupStep.recommendations]: (
        <FormattedMessage defaultMessage="Which recommendations would you like to receive?" />
      ),
    }}
  </FoldStep>
)
const CoachMessageBody = ({ step }: { step: SetupStep }) => (
  <FoldStep {...{ step }}>
    {{
      [SetupStep.generic]: (
        <FormattedMessage defaultMessage="In order to prepare meaningful and accurate recommendations I need to get some information about you. Once done I will be able to tell you when you will likely accomplish your goals." />
      ),
      [SetupStep.workAndSleep]: (
        <FormattedMessage defaultMessage="Your profession will help me to prepare better recommendations regarding nutrition and physical activities." />
      ),
      [SetupStep.recommendations]: (
        <FormattedMessage defaultMessage="I can give you nutrition and exercises recommendations if you like. You can always add activities manually no matter your choice." />
      ),
    }}
  </FoldStep>
)

export const SetupPage: FC<SetupPageProps> = ({
  id,
  className,
  defaultValues,
  setUnitSelectionLocal,
  onSubmit,
}) => {
  const params = useParams<{ step: string }>()

  const [searchParams, _] = useSearchParams()
  const redo = searchParams.has("redo")

  const theme = useTheme()
  const { handleSubmit, ...form } = useForm<FormData>({
    defaultValues,
  })
  const { control } = form

  const mass = useWatch({ control, name: "unitMass" })
  const lengthHeight = useWatch({ control, name: "unitLengthHeight" })
  const lengthDistance = useWatch({ control, name: "unitLengthDistance" })

  useEffect(() => {
    setUnitSelectionLocal({ mass, lengthHeight, lengthDistance })()
  }, [setUnitSelectionLocal, mass, lengthHeight, lengthDistance])

  const [ref, inView] = useInView()

  if (params.step === undefined || !(params.step in SetupStep)) {
    return <Navigate replace to={`/setup/generic${redo ? "?redo" : ""}`} />
  }
  const step = params.step as SetupStep

  const steps = Object.values(SetupStep)
  const stepIndex = pipe(
    steps,
    Arr.findIndex((s) => s === step),
    unsafeFromSome,
  )
  const prev = getStepPath((i) => i - 1, step, steps)
  const next = getStepPath((i) => i + 1, step, steps)

  return (
    <LoggedOutTemplate
      header={
        <CenteredRow>
          {inView || (
            <Media queries={theme.media}>
              {({ sm }) =>
                sm && (
                  <Col xs="auto">
                    <HeaderCoachMessage>
                      <CoachMessageTitle {...{ step }} />
                    </HeaderCoachMessage>
                  </Col>
                )
              }
            </Media>
          )}

          <Col xs="auto">
            <FormattedMessage
              defaultMessage="Step {current, number} of {total, number}"
              values={{ current: stepIndex + 1, total: steps.length }}
            />
          </Col>
        </CenteredRow>
      }
      {...{ className }}
    >
      <Container>
        <Row>
          <Col xs={12}>
            <BodyCoachMessage ref={ref}>
              <header>
                <CoachMessageTitleWrapper>
                  <CoachMessageTitle {...{ step }} />
                </CoachMessageTitleWrapper>

                <p>
                  <CoachMessageBody {...{ step }} />
                </p>
              </header>
            </BodyCoachMessage>
          </Col>
        </Row>

        <FormProvider {...{ handleSubmit }} {...form}>
          <form onSubmit={handleSubmit((v) => onSubmit(v))}>
            <WizardContents {...{ step }}>
              {{
                [SetupStep.generic]: (
                  <Fieldset>
                    <Fieldset>
                      <UnitFieldset id={`${id}-units`} expandable />
                    </Fieldset>

                    <Fieldset>
                      <SexAndBirthDateFieldset id={`${id}-sex`} />
                    </Fieldset>

                    <Fieldset>
                      <HeightAndMassFieldset id={`${id}-heightAndMass`} />
                    </Fieldset>

                    <Fieldset>
                      <BodyCompositionFieldset id={`${id}-bodyComposition`} />
                    </Fieldset>
                  </Fieldset>
                ),
                [SetupStep.workAndSleep]: (
                  <Fieldset>
                    <Fieldset>
                      <WorkFieldsetContainer id={`${id}-work`} />
                    </Fieldset>

                    <Fieldset>
                      <SleepFieldset id={`${id}-sleep`} />
                    </Fieldset>
                  </Fieldset>
                ),
                [SetupStep.recommendations]: (
                  <Fieldset>
                    <RecommendationsFieldset id={`${id}-recommendations`} />
                  </Fieldset>
                ),
              }}
            </WizardContents>

            <StickyFooter>
              <Col xs={4} sm={3} md={2}>
                {pipe(
                  prev,
                  Opt.fold(
                    () => null,
                    (prev) => (
                      <Button
                        tag={Link}
                        to={`${prev}${redo ? "?redo" : ""}`}
                        color="link"
                      >
                        <FaArrowLeft />{" "}
                        <FormattedMessage defaultMessage="Previous" />
                      </Button>
                    ),
                  ),
                )}
              </Col>

              <Col xs={4} sm={3} md={2}>
                {pipe(
                  next,
                  Opt.fold(
                    () => (
                      <Button type="submit" color="success">
                        <FormattedMessage defaultMessage="Finish!" />{" "}
                        <FaArrowRight />
                      </Button>
                    ),
                    (next) => (
                      <Button
                        type="button"
                        tag={Link}
                        to={`${next}${redo ? "?redo" : ""}`}
                        color="success"
                      >
                        <FormattedMessage defaultMessage="Next" />{" "}
                        <FaArrowRight />
                      </Button>
                    ),
                  ),
                )}
              </Col>
            </StickyFooter>
          </form>
        </FormProvider>
      </Container>
    </LoggedOutTemplate>
  )
}

export const SetupPageContainer = connector(SetupPage)
