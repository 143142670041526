/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InviteCodeFromJSON, } from "../models/index";
import * as runtime from "../runtime";
/**
 *
 */
export class InvitesApi extends runtime.BaseAPI {
    /**
     * Gets all invite codes (Admin-only).
     */
    async userManagementApiInvitesGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/invites`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InviteCodeFromJSON));
    }
    /**
     * Gets all invite codes (Admin-only).
     */
    async userManagementApiInvitesGet(initOverrides) {
        const response = await this.userManagementApiInvitesGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * Generate a new invite code. (Admin-only).
     */
    async userManagementApiInvitesPostRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/invites`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => InviteCodeFromJSON(jsonValue));
    }
    /**
     * Generate a new invite code. (Admin-only).
     */
    async userManagementApiInvitesPost(initOverrides) {
        const response = await this.userManagementApiInvitesPostRaw(initOverrides);
        return await response.value();
    }
}
