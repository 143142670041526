/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventFromJSON, EventToJSON } from "./Event";
import { TimeslotApiFromJSON, TimeslotApiToJSON, } from "./TimeslotApi";
/**
 * Check if a given object implements the EventResponse interface.
 */
export function instanceOfEventResponse(value) {
    if (!("events" in value) || value.events === undefined)
        return false;
    return true;
}
export function EventResponseFromJSON(json) {
    return EventResponseFromJSONTyped(json, false);
}
export function EventResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        events: json.events.map(EventFromJSON),
        timeslots: json.timeslots == null
            ? undefined
            : json.timeslots.map(TimeslotApiFromJSON),
    };
}
export function EventResponseToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        events: value.events.map(EventToJSON),
        timeslots: value.timeslots == null
            ? undefined
            : value.timeslots.map(TimeslotApiToJSON),
    };
}
