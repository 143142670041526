/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MusclePreferencesFromJSON, MusclePreferencesToJSON, } from "./MusclePreferences";
/**
 * Check if a given object implements the OutputMuscleGroup interface.
 */
export function instanceOfOutputMuscleGroup(value) {
    if (!("title" in value) || value.title === undefined)
        return false;
    if (!("description" in value) || value.description === undefined)
        return false;
    return true;
}
export function OutputMuscleGroupFromJSON(json) {
    return OutputMuscleGroupFromJSONTyped(json, false);
}
export function OutputMuscleGroupFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        id: json.id == null ? undefined : json.id,
        title: json.title,
        description: json.description,
        enabled: json.enabled == null ? undefined : json.enabled,
        alignment: json.alignment == null ? undefined : json.alignment,
        importanceFactor: json.importanceFactor == null ? undefined : json.importanceFactor,
        musclePreferences: json.musclePreferences == null
            ? undefined
            : json.musclePreferences.map(MusclePreferencesFromJSON),
    };
}
export function OutputMuscleGroupToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        id: value.id,
        title: value.title,
        description: value.description,
        enabled: value.enabled,
        alignment: value.alignment,
        importanceFactor: value.importanceFactor,
        musclePreferences: value.musclePreferences == null
            ? undefined
            : value.musclePreferences.map(MusclePreferencesToJSON),
    };
}
