/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityFromJSON, ActivityToJSON, } from "./Activity";
/**
 * Check if a given object implements the SportsActivitiesResponse interface.
 */
export function instanceOfSportsActivitiesResponse(value) {
    if (!("activities" in value) || value.activities === undefined)
        return false;
    return true;
}
export function SportsActivitiesResponseFromJSON(json) {
    return SportsActivitiesResponseFromJSONTyped(json, false);
}
export function SportsActivitiesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        activities: json.activities.map(ActivityFromJSON),
    };
}
export function SportsActivitiesResponseToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        activities: value.activities.map((a) => ActivityToJSON(a)),
    };
}
