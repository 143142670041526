/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UnitDistanceFromJSON, UnitDistanceToJSON, } from "./UnitDistance";
import { UnitHeightFromJSON, UnitHeightToJSON, } from "./UnitHeight";
import { UnitMassFromJSON, UnitMassToJSON, } from "./UnitMass";
/**
 * Check if a given object implements the UnitPreferences interface.
 */
export function instanceOfUnitPreferences(value) {
    if (!("mass" in value) || value.mass === undefined)
        return false;
    if (!("height" in value) || value.height === undefined)
        return false;
    if (!("distance" in value) || value.distance === undefined)
        return false;
    return true;
}
export function UnitPreferencesFromJSON(json) {
    return UnitPreferencesFromJSONTyped(json, false);
}
export function UnitPreferencesFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        mass: UnitMassFromJSON(json.mass),
        height: UnitHeightFromJSON(json.height),
        distance: UnitDistanceFromJSON(json.distance),
    };
}
export function UnitPreferencesToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        mass: UnitMassToJSON(value.mass),
        height: UnitHeightToJSON(value.height),
        distance: UnitDistanceToJSON(value.distance),
    };
}
