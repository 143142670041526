/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Option1StringFromJSON, Option1StringToJSON, } from "./Option1String";
/**
 * Check if a given object implements the SportsOptions interface.
 */
export function instanceOfSportsOptions(value) {
    if (!("id" in value) || value.id === undefined)
        return false;
    if (!("workoutMethod" in value) || value.workoutMethod === undefined)
        return false;
    if (!("workoutTarget" in value) || value.workoutTarget === undefined)
        return false;
    return true;
}
export function SportsOptionsFromJSON(json) {
    return SportsOptionsFromJSONTyped(json, false);
}
export function SportsOptionsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        id: json.id,
        workoutMethod: json.workoutMethod.map(Option1StringFromJSON),
        workoutTarget: json.workoutTarget.map(Option1StringFromJSON),
    };
}
export function SportsOptionsToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        id: value.id,
        workoutMethod: value.workoutMethod.map(Option1StringToJSON),
        workoutTarget: value.workoutTarget.map(Option1StringToJSON),
    };
}
