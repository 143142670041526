/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VideoFromJSON, VideoToJSON } from "./Video";
/**
 * Check if a given object implements the WorkoutVideo interface.
 */
export function instanceOfWorkoutVideo(value) {
    if (!("video" in value) || value.video === undefined)
        return false;
    if (!("id" in value) || value.id === undefined)
        return false;
    return true;
}
export function WorkoutVideoFromJSON(json) {
    return WorkoutVideoFromJSONTyped(json, false);
}
export function WorkoutVideoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        video: VideoFromJSON(json.video),
        title: json.title == null ? undefined : json.title,
        id: json.id,
    };
}
export function WorkoutVideoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        video: VideoToJSON(value.video),
        title: value.title,
        id: value.id,
    };
}
