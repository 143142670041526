/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the MuscleShare interface.
 */
export function instanceOfMuscleShare(value) {
    if (!("muscleId" in value) || value.muscleId === undefined)
        return false;
    if (!("intensity" in value) || value.intensity === undefined)
        return false;
    return true;
}
export function MuscleShareFromJSON(json) {
    return MuscleShareFromJSONTyped(json, false);
}
export function MuscleShareFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        muscleId: json.muscleId,
        intensity: json.intensity,
    };
}
export function MuscleShareToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        muscleId: value.muscleId,
        intensity: value.intensity,
    };
}
