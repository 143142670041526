/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventFromJSON, EventToJSON } from "./Event";
/**
 * Check if a given object implements the EventRequest interface.
 */
export function instanceOfEventRequest(value) {
    if (!("events" in value) || value.events === undefined)
        return false;
    return true;
}
export function EventRequestFromJSON(json) {
    return EventRequestFromJSONTyped(json, false);
}
export function EventRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        events: json.events.map(EventFromJSON),
    };
}
export function EventRequestToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        events: value.events.map(EventToJSON),
    };
}
