/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BodyTypeFromJSON, BodyTypeToJSON, } from "./BodyType";
/**
 * Check if a given object implements the BodyData interface.
 */
export function instanceOfBodyData(value) {
    return true;
}
export function BodyDataFromJSON(json) {
    return BodyDataFromJSONTyped(json, false);
}
export function BodyDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        height: json.height == null ? undefined : json.height,
        mass: json.mass == null ? undefined : json.mass,
        fatShare: json.fatShare == null ? undefined : json.fatShare,
        waterShare: json.waterShare == null ? undefined : json.waterShare,
        fatFreeShare: json.fatFreeShare == null ? undefined : json.fatFreeShare,
        bodyType: json.bodyType == null ? undefined : BodyTypeFromJSON(json.bodyType),
    };
}
export function BodyDataToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        height: value.height,
        mass: value.mass,
        fatShare: value.fatShare,
        waterShare: value.waterShare,
        fatFreeShare: value.fatFreeShare,
        bodyType: BodyTypeToJSON(value.bodyType),
    };
}
