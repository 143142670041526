/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the MusclePreferences interface.
 */
export function instanceOfMusclePreferences(value) {
    if (!("id" in value) || value.id === undefined)
        return false;
    if (!("enabled" in value) || value.enabled === undefined)
        return false;
    if (!("priority" in value) || value.priority === undefined)
        return false;
    return true;
}
export function MusclePreferencesFromJSON(json) {
    return MusclePreferencesFromJSONTyped(json, false);
}
export function MusclePreferencesFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        id: json.id,
        enabled: json.enabled,
        priority: json.priority,
    };
}
export function MusclePreferencesToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        id: value.id,
        enabled: value.enabled,
        priority: value.priority,
    };
}
