/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AuthorizationCodeRequest interface.
 */
export function instanceOfAuthorizationCodeRequest(value) {
    if (!("authorizationCode" in value) || value.authorizationCode === undefined)
        return false;
    if (!("scopes" in value) || value.scopes === undefined)
        return false;
    if (!("redirectUri" in value) || value.redirectUri === undefined)
        return false;
    return true;
}
export function AuthorizationCodeRequestFromJSON(json) {
    return AuthorizationCodeRequestFromJSONTyped(json, false);
}
export function AuthorizationCodeRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        authorizationCode: json.authorizationCode,
        scopes: json.scopes,
        redirectUri: json.redirectUri,
    };
}
export function AuthorizationCodeRequestToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        authorizationCode: value.authorizationCode,
        scopes: value.scopes,
        redirectUri: value.redirectUri,
    };
}
