/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModelSetFromJSON, ModelSetToJSON, } from "./ModelSet";
/**
 * Check if a given object implements the ActivityMax interface.
 */
export function instanceOfActivityMax(value) {
    if (!("max" in value) || value.max === undefined)
        return false;
    if (!("intensity" in value) || value.intensity === undefined)
        return false;
    return true;
}
export function ActivityMaxFromJSON(json) {
    return ActivityMaxFromJSONTyped(json, false);
}
export function ActivityMaxFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        max: json.max,
        intensity: json.intensity,
        sets: json.sets == null
            ? undefined
            : json.sets.map(ModelSetFromJSON),
    };
}
export function ActivityMaxToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        max: value.max,
        intensity: value.intensity,
        sets: value.sets == null
            ? undefined
            : value.sets.map(ModelSetToJSON),
    };
}
