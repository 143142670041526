/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityInstanceExerciseFromJSONTyped, ActivityInstanceExerciseToJSON, } from "./ActivityInstanceExercise";
import { ActivityInstanceGroupFromJSONTyped, ActivityInstanceGroupToJSON, } from "./ActivityInstanceGroup";
import { ActivityInstanceJobFromJSONTyped, ActivityInstanceJobToJSON, } from "./ActivityInstanceJob";
import { ActivityInstanceMusclesFromJSONTyped, ActivityInstanceMusclesToJSON, } from "./ActivityInstanceMuscles";
import { ActivityInstanceSleepFromJSONTyped, ActivityInstanceSleepToJSON, } from "./ActivityInstanceSleep";
import { ActivityType, ActivityTypeFromJSON, ActivityTypeToJSON, } from "./ActivityType";
/**
 * Check if a given object implements the ActivityInstance interface.
 */
export function instanceOfActivityInstance(value) {
    if (!("$type" in value) || value.$type === undefined)
        return false;
    return true;
}
export function ActivityInstanceFromJSON(json) {
    return ActivityInstanceFromJSONTyped(json, false);
}
export function ActivityInstanceFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json.$type === "exercise") {
            return ActivityInstanceExerciseFromJSONTyped(json, true);
        }
        if (json.$type === "group") {
            return ActivityInstanceGroupFromJSONTyped(json, true);
        }
        if (json.$type === "job") {
            return ActivityInstanceJobFromJSONTyped(json, true);
        }
        if (json.$type === "muscles") {
            return ActivityInstanceMusclesFromJSONTyped(json, true);
        }
        if (json.$type === "sleep") {
            return ActivityInstanceSleepFromJSONTyped(json, true);
        }
    }
    return {
        $type: ActivityTypeFromJSON(json.$type),
    };
}
export function ActivityInstanceToJSON(value, ignoreDiscriminator) {
    if (value == null) {
        return value;
    }
    if (!ignoreDiscriminator) {
        if (value.$type === ActivityType.Exercise) {
            return ActivityInstanceExerciseToJSON(value);
        }
        if (value.$type === ActivityType.Group) {
            return ActivityInstanceGroupToJSON(value);
        }
        if (value.$type === ActivityType.Job) {
            return ActivityInstanceJobToJSON(value);
        }
        if (value.$type === ActivityType.Muscles) {
            return ActivityInstanceMusclesToJSON(value);
        }
        if (value.$type === ActivityType.Sleep) {
            return ActivityInstanceSleepToJSON(value);
        }
    }
    return {
        $type: ActivityTypeToJSON(value.$type),
    };
}
