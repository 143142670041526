/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the UserAccount interface.
 */
export function instanceOfUserAccount(value) {
    if (!("email" in value) || value.email === undefined)
        return false;
    return true;
}
export function UserAccountFromJSON(json) {
    return UserAccountFromJSONTyped(json, false);
}
export function UserAccountFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        uid: json.uid == null ? undefined : json.uid,
        created: json.created == null ? undefined : new Date(json.created),
        email: json.email,
        password: json.password == null ? undefined : json.password,
        setupIncomplete: json.setupIncomplete == null ? undefined : json.setupIncomplete,
        nickname: json.nickname == null ? undefined : json.nickname,
        firstName: json.firstName == null ? undefined : json.firstName,
        lastName: json.lastName == null ? undefined : json.lastName,
        applyDemoData: json.applyDemoData == null ? undefined : json.applyDemoData,
        ip: json.ip == null ? undefined : json.ip,
        profilePicture: json.profilePicture == null ? undefined : json.profilePicture,
        inviteCode: json.inviteCode == null ? undefined : json.inviteCode,
        googleApiActive: json.googleApiActive == null ? undefined : json.googleApiActive,
        googleApiScopes: json.googleApiScopes == null ? undefined : json.googleApiScopes,
    };
}
export function UserAccountToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        uid: value.uid,
        created: value.created == null ? undefined : value.created.toISOString(),
        email: value.email,
        password: value.password,
        setupIncomplete: value.setupIncomplete,
        nickname: value.nickname,
        firstName: value.firstName,
        lastName: value.lastName,
        applyDemoData: value.applyDemoData,
        ip: value.ip,
        profilePicture: value.profilePicture,
        inviteCode: value.inviteCode,
        googleApiActive: value.googleApiActive,
        googleApiScopes: value.googleApiScopes,
    };
}
