/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SexFromJSON, SexToJSON } from "./Sex";
import { UnitPreferencesFromJSON, UnitPreferencesToJSON, } from "./UnitPreferences";
/**
 * Check if a given object implements the UserData interface.
 */
export function instanceOfUserData(value) {
    if (!("sex" in value) || value.sex === undefined)
        return false;
    if (!("birthDate" in value) || value.birthDate === undefined)
        return false;
    if (!("units" in value) || value.units === undefined)
        return false;
    return true;
}
export function UserDataFromJSON(json) {
    return UserDataFromJSONTyped(json, false);
}
export function UserDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        sex: SexFromJSON(json.sex),
        birthDate: new Date(json.birthDate),
        units: UnitPreferencesFromJSON(json.units),
    };
}
export function UserDataToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        sex: SexToJSON(value.sex),
        birthDate: value.birthDate.toISOString().substring(0, 10),
        units: UnitPreferencesToJSON(value.units),
    };
}
