/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import { ActivityExerciseFromJSON, ActivityExerciseToJSON, } from "./ActivityExercise";
import { ActivityInstanceFromJSONTyped, ActivityInstanceToJSON, } from "./ActivityInstance";
import { ActivityMuscleInfoFromJSON, ActivityMuscleInfoToJSON, } from "./ActivityMuscleInfo";
import { ActivityTypeFromJSON, ActivityTypeToJSON, } from "./ActivityType";
import { ModelSetFromJSON, ModelSetToJSON, } from "./ModelSet";
/**
 * Check if a given object implements the ActivityInstanceExercise interface.
 */
export function instanceOfActivityInstanceExercise(value) {
    if (!("activity" in value) || value.activity === undefined)
        return false;
    if (!("$type" in value) || value.$type === undefined)
        return false;
    if (!("alignment" in value) || value.alignment === undefined)
        return false;
    if (!("muscles" in value) || value.muscles === undefined)
        return false;
    return true;
}
export function ActivityInstanceExerciseFromJSON(json) {
    return ActivityInstanceExerciseFromJSONTyped(json, false);
}
export function ActivityInstanceExerciseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        ...ActivityInstanceFromJSONTyped(json, ignoreDiscriminator),
        activity: ActivityExerciseFromJSON(json.activity),
        $type: ActivityTypeFromJSON(json.$type),
        duration: json.duration == null ? undefined : json.duration,
        distance: json.distance == null ? undefined : json.distance,
        intensity: json.intensity == null ? undefined : json.intensity,
        sets: json.sets == null
            ? undefined
            : json.sets.map(ModelSetFromJSON),
        alignment: json.alignment,
        importanceFactor: json.importanceFactor == null ? undefined : json.importanceFactor,
        muscles: mapValues(json.muscles, ActivityMuscleInfoFromJSON),
    };
}
export function ActivityInstanceExerciseToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        ...ActivityInstanceToJSON(value, true),
        activity: ActivityExerciseToJSON(value.activity),
        $type: ActivityTypeToJSON(value.$type),
        duration: value.duration,
        distance: value.distance,
        intensity: value.intensity,
        sets: value.sets == null
            ? undefined
            : value.sets.map(ModelSetToJSON),
        alignment: value.alignment,
        importanceFactor: value.importanceFactor,
        muscles: mapValues(value.muscles, ActivityMuscleInfoToJSON),
    };
}
