/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const UnitMass = {
    Kilogram: "kilogram",
    Pound: "pound",
};
export function instanceOfUnitMass(value) {
    for (const key in UnitMass) {
        if (Object.prototype.hasOwnProperty.call(UnitMass, key)) {
            if (UnitMass[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function UnitMassFromJSON(json) {
    return UnitMassFromJSONTyped(json, false);
}
export function UnitMassFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function UnitMassToJSON(value) {
    return value;
}
