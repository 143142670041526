/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HelpFromJSON, HelpToJSON, } from "../models/index";
import * as runtime from "../runtime";
/**
 *
 */
export class HelpApi extends runtime.BaseAPI {
    /**
     */
    async userManagementApiUsersUserIdHelpGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling userManagementApiUsersUserIdHelpGet().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/help`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => HelpFromJSON(jsonValue));
    }
    /**
     */
    async userManagementApiUsersUserIdHelpGet(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdHelpGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userManagementApiUsersUserIdHelpPutRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling userManagementApiUsersUserIdHelpPut().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/help`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: HelpToJSON(requestParameters.help),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => HelpFromJSON(jsonValue));
    }
    /**
     */
    async userManagementApiUsersUserIdHelpPut(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdHelpPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
