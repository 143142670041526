// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file uom/v1/length.proto (package uom.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from enum uom.v1.UnitLength
 */
export enum UnitLength {
  /**
   * @generated from enum value: UNIT_LENGTH_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * SI base unit
   *
   * @generated from enum value: UNIT_LENGTH_METER = 1;
   */
  METER = 1,

  /**
   * @generated from enum value: UNIT_LENGTH_KILOMETER = 2;
   */
  KILOMETER = 2,

  /**
   * @generated from enum value: UNIT_LENGTH_CENTIMETER = 3;
   */
  CENTIMETER = 3,

  /**
   * @generated from enum value: UNIT_LENGTH_MILE = 4;
   */
  MILE = 4,

  /**
   * @generated from enum value: UNIT_LENGTH_FOOT = 5;
   */
  FOOT = 5,

  /**
   * @generated from enum value: UNIT_LENGTH_INCH = 6;
   */
  INCH = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(UnitLength)
proto3.util.setEnumType(UnitLength, "uom.v1.UnitLength", [
  { no: 0, name: "UNIT_LENGTH_UNSPECIFIED" },
  { no: 1, name: "UNIT_LENGTH_METER" },
  { no: 2, name: "UNIT_LENGTH_KILOMETER" },
  { no: 3, name: "UNIT_LENGTH_CENTIMETER" },
  { no: 4, name: "UNIT_LENGTH_MILE" },
  { no: 5, name: "UNIT_LENGTH_FOOT" },
  { no: 6, name: "UNIT_LENGTH_INCH" },
]);

/**
 * @generated from message uom.v1.LengthInt32
 */
export class LengthInt32 extends Message<LengthInt32> {
  /**
   * @generated from field: uom.v1.UnitLength unit = 1;
   */
  unit = UnitLength.UNSPECIFIED;

  /**
   * @generated from field: int32 value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<LengthInt32>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.LengthInt32";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitLength) },
    { no: 2, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LengthInt32 {
    return new LengthInt32().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LengthInt32 {
    return new LengthInt32().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LengthInt32 {
    return new LengthInt32().fromJsonString(jsonString, options);
  }

  static equals(a: LengthInt32 | PlainMessage<LengthInt32> | undefined, b: LengthInt32 | PlainMessage<LengthInt32> | undefined): boolean {
    return proto3.util.equals(LengthInt32, a, b);
  }
}

/**
 * @generated from message uom.v1.LengthInt64
 */
export class LengthInt64 extends Message<LengthInt64> {
  /**
   * @generated from field: uom.v1.UnitLength unit = 1;
   */
  unit = UnitLength.UNSPECIFIED;

  /**
   * @generated from field: int64 value = 2;
   */
  value = protoInt64.zero;

  constructor(data?: PartialMessage<LengthInt64>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.LengthInt64";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitLength) },
    { no: 2, name: "value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LengthInt64 {
    return new LengthInt64().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LengthInt64 {
    return new LengthInt64().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LengthInt64 {
    return new LengthInt64().fromJsonString(jsonString, options);
  }

  static equals(a: LengthInt64 | PlainMessage<LengthInt64> | undefined, b: LengthInt64 | PlainMessage<LengthInt64> | undefined): boolean {
    return proto3.util.equals(LengthInt64, a, b);
  }
}

/**
 * @generated from message uom.v1.LengthUint32
 */
export class LengthUint32 extends Message<LengthUint32> {
  /**
   * @generated from field: uom.v1.UnitLength unit = 1;
   */
  unit = UnitLength.UNSPECIFIED;

  /**
   * @generated from field: uint32 value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<LengthUint32>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.LengthUint32";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitLength) },
    { no: 2, name: "value", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LengthUint32 {
    return new LengthUint32().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LengthUint32 {
    return new LengthUint32().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LengthUint32 {
    return new LengthUint32().fromJsonString(jsonString, options);
  }

  static equals(a: LengthUint32 | PlainMessage<LengthUint32> | undefined, b: LengthUint32 | PlainMessage<LengthUint32> | undefined): boolean {
    return proto3.util.equals(LengthUint32, a, b);
  }
}

/**
 * @generated from message uom.v1.LengthUint64
 */
export class LengthUint64 extends Message<LengthUint64> {
  /**
   * @generated from field: uom.v1.UnitLength unit = 1;
   */
  unit = UnitLength.UNSPECIFIED;

  /**
   * @generated from field: uint64 value = 2;
   */
  value = protoInt64.zero;

  constructor(data?: PartialMessage<LengthUint64>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.LengthUint64";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitLength) },
    { no: 2, name: "value", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LengthUint64 {
    return new LengthUint64().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LengthUint64 {
    return new LengthUint64().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LengthUint64 {
    return new LengthUint64().fromJsonString(jsonString, options);
  }

  static equals(a: LengthUint64 | PlainMessage<LengthUint64> | undefined, b: LengthUint64 | PlainMessage<LengthUint64> | undefined): boolean {
    return proto3.util.equals(LengthUint64, a, b);
  }
}

/**
 * @generated from message uom.v1.LengthFloat
 */
export class LengthFloat extends Message<LengthFloat> {
  /**
   * @generated from field: uom.v1.UnitLength unit = 1;
   */
  unit = UnitLength.UNSPECIFIED;

  /**
   * @generated from field: float value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<LengthFloat>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.LengthFloat";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitLength) },
    { no: 2, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LengthFloat {
    return new LengthFloat().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LengthFloat {
    return new LengthFloat().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LengthFloat {
    return new LengthFloat().fromJsonString(jsonString, options);
  }

  static equals(a: LengthFloat | PlainMessage<LengthFloat> | undefined, b: LengthFloat | PlainMessage<LengthFloat> | undefined): boolean {
    return proto3.util.equals(LengthFloat, a, b);
  }
}

/**
 * @generated from message uom.v1.LengthDouble
 */
export class LengthDouble extends Message<LengthDouble> {
  /**
   * @generated from field: uom.v1.UnitLength unit = 1;
   */
  unit = UnitLength.UNSPECIFIED;

  /**
   * @generated from field: double value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<LengthDouble>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.LengthDouble";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitLength) },
    { no: 2, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LengthDouble {
    return new LengthDouble().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LengthDouble {
    return new LengthDouble().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LengthDouble {
    return new LengthDouble().fromJsonString(jsonString, options);
  }

  static equals(a: LengthDouble | PlainMessage<LengthDouble> | undefined, b: LengthDouble | PlainMessage<LengthDouble> | undefined): boolean {
    return proto3.util.equals(LengthDouble, a, b);
  }
}

/**
 * specifies values in SI base unit (metre)
 *
 * @generated from message uom.v1.CanonicalLengthInt32
 */
export class CanonicalLengthInt32 extends Message<CanonicalLengthInt32> {
  /**
   * @generated from field: int32 value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<CanonicalLengthInt32>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.CanonicalLengthInt32";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanonicalLengthInt32 {
    return new CanonicalLengthInt32().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanonicalLengthInt32 {
    return new CanonicalLengthInt32().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanonicalLengthInt32 {
    return new CanonicalLengthInt32().fromJsonString(jsonString, options);
  }

  static equals(a: CanonicalLengthInt32 | PlainMessage<CanonicalLengthInt32> | undefined, b: CanonicalLengthInt32 | PlainMessage<CanonicalLengthInt32> | undefined): boolean {
    return proto3.util.equals(CanonicalLengthInt32, a, b);
  }
}

/**
 * @generated from message uom.v1.CanonicalLengthInt64
 */
export class CanonicalLengthInt64 extends Message<CanonicalLengthInt64> {
  /**
   * @generated from field: int64 value = 1;
   */
  value = protoInt64.zero;

  constructor(data?: PartialMessage<CanonicalLengthInt64>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.CanonicalLengthInt64";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanonicalLengthInt64 {
    return new CanonicalLengthInt64().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanonicalLengthInt64 {
    return new CanonicalLengthInt64().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanonicalLengthInt64 {
    return new CanonicalLengthInt64().fromJsonString(jsonString, options);
  }

  static equals(a: CanonicalLengthInt64 | PlainMessage<CanonicalLengthInt64> | undefined, b: CanonicalLengthInt64 | PlainMessage<CanonicalLengthInt64> | undefined): boolean {
    return proto3.util.equals(CanonicalLengthInt64, a, b);
  }
}

/**
 * @generated from message uom.v1.CanonicalLengthUint32
 */
export class CanonicalLengthUint32 extends Message<CanonicalLengthUint32> {
  /**
   * @generated from field: uint32 value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<CanonicalLengthUint32>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.CanonicalLengthUint32";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanonicalLengthUint32 {
    return new CanonicalLengthUint32().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanonicalLengthUint32 {
    return new CanonicalLengthUint32().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanonicalLengthUint32 {
    return new CanonicalLengthUint32().fromJsonString(jsonString, options);
  }

  static equals(a: CanonicalLengthUint32 | PlainMessage<CanonicalLengthUint32> | undefined, b: CanonicalLengthUint32 | PlainMessage<CanonicalLengthUint32> | undefined): boolean {
    return proto3.util.equals(CanonicalLengthUint32, a, b);
  }
}

/**
 * @generated from message uom.v1.CanonicalLengthUint64
 */
export class CanonicalLengthUint64 extends Message<CanonicalLengthUint64> {
  /**
   * @generated from field: uint64 value = 1;
   */
  value = protoInt64.zero;

  constructor(data?: PartialMessage<CanonicalLengthUint64>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.CanonicalLengthUint64";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanonicalLengthUint64 {
    return new CanonicalLengthUint64().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanonicalLengthUint64 {
    return new CanonicalLengthUint64().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanonicalLengthUint64 {
    return new CanonicalLengthUint64().fromJsonString(jsonString, options);
  }

  static equals(a: CanonicalLengthUint64 | PlainMessage<CanonicalLengthUint64> | undefined, b: CanonicalLengthUint64 | PlainMessage<CanonicalLengthUint64> | undefined): boolean {
    return proto3.util.equals(CanonicalLengthUint64, a, b);
  }
}

/**
 * @generated from message uom.v1.CanonicalLengthFloat
 */
export class CanonicalLengthFloat extends Message<CanonicalLengthFloat> {
  /**
   * @generated from field: float value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<CanonicalLengthFloat>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.CanonicalLengthFloat";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanonicalLengthFloat {
    return new CanonicalLengthFloat().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanonicalLengthFloat {
    return new CanonicalLengthFloat().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanonicalLengthFloat {
    return new CanonicalLengthFloat().fromJsonString(jsonString, options);
  }

  static equals(a: CanonicalLengthFloat | PlainMessage<CanonicalLengthFloat> | undefined, b: CanonicalLengthFloat | PlainMessage<CanonicalLengthFloat> | undefined): boolean {
    return proto3.util.equals(CanonicalLengthFloat, a, b);
  }
}

/**
 * @generated from message uom.v1.CanonicalLengthDouble
 */
export class CanonicalLengthDouble extends Message<CanonicalLengthDouble> {
  /**
   * @generated from field: double value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<CanonicalLengthDouble>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.CanonicalLengthDouble";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanonicalLengthDouble {
    return new CanonicalLengthDouble().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanonicalLengthDouble {
    return new CanonicalLengthDouble().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanonicalLengthDouble {
    return new CanonicalLengthDouble().fromJsonString(jsonString, options);
  }

  static equals(a: CanonicalLengthDouble | PlainMessage<CanonicalLengthDouble> | undefined, b: CanonicalLengthDouble | PlainMessage<CanonicalLengthDouble> | undefined): boolean {
    return proto3.util.equals(CanonicalLengthDouble, a, b);
  }
}

