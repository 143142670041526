/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityFromJSON, ActivityToJSON, } from "./Activity";
import { OutputMuscleGroupFromJSON, OutputMuscleGroupToJSON, } from "./OutputMuscleGroup";
/**
 * Check if a given object implements the ActivitiesResponse interface.
 */
export function instanceOfActivitiesResponse(value) {
    if (!("muscleGroups" in value) || value.muscleGroups === undefined)
        return false;
    if (!("activities" in value) || value.activities === undefined)
        return false;
    return true;
}
export function ActivitiesResponseFromJSON(json) {
    return ActivitiesResponseFromJSONTyped(json, false);
}
export function ActivitiesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        muscleGroups: json.muscleGroups.map(OutputMuscleGroupFromJSON),
        activities: json.activities.map(ActivityFromJSON),
    };
}
export function ActivitiesResponseToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        muscleGroups: value.muscleGroups.map(OutputMuscleGroupToJSON),
        activities: value.activities.map((a) => ActivityToJSON(a)),
    };
}
