import { pipe } from "fp-ts/es6/function";
import * as Opt from "fp-ts/es6/Option";
import * as Ord from "fp-ts/es6/Ord";
import { Lens, Prism } from "monocle-ts";
import { ioTsPrism } from "../internal";
import { isoLocal } from "./Local";
import { Temporal } from "@js-temporal/polyfill";
import * as t from "io-ts";
export const isoLocalDuration = isoLocal();
export const mkLocalDuration = ({ years, months, weeks, days, hours, minutes, seconds, }) => pipe(Temporal.Duration.from({
    years,
    months,
    weeks,
    days,
    hours,
    minutes,
    // Fractional seconds aren't supported
    microseconds: (seconds !== null && seconds !== void 0 ? seconds : 0) * 1000000,
}), isoLocalDuration.reverseGet);
export const _years = isoLocalDuration.composeLens(new Lens((s) => s.years, (years) => (s) => s.with({ years })));
export const _months = isoLocalDuration.composeLens(new Lens((s) => s.months, (months) => (s) => s.with({ months })));
export const _weeks = isoLocalDuration.composeLens(new Lens((s) => s.weeks, (weeks) => (s) => s.with({ weeks })));
export const _days = isoLocalDuration.composeLens(new Lens((s) => s.days, (days) => (s) => s.with({ days })));
export const _hours = isoLocalDuration.composeLens(new Lens((s) => s.hours, (hours) => (s) => s.with({ hours })));
export const _minutes = isoLocalDuration.composeLens(new Lens((s) => s.minutes, (minutes) => (s) => s.with({ minutes })));
export const _seconds = isoLocalDuration.composeLens(new Lens((s) => s.seconds, (seconds) => (s) => s.with({ seconds })));
export const localDuration = {
    ...Ord.fromCompare((a, b) => Temporal.Duration.compare(isoLocalDuration.get(a), isoLocalDuration.get(b))),
    empty: mkLocalDuration({}),
    concat: (a, b) => isoLocalDuration.reverseGet(isoLocalDuration.get(a).add(isoLocalDuration.get(b))),
    inverse: (a) => isoLocalDuration.reverseGet(isoLocalDuration.get(a).negated()),
    add: (t, d) => t.add(isoLocalDuration.get(d)),
    difference: (t, t2) => isoLocalDuration.reverseGet(t.until(t2)),
};
export const equals = (d2) => (d) => localDuration.equals(d, d2);
export const compare = (d2) => (d) => localDuration.compare(d, d2);
export const concat = (d2) => (d) => localDuration.concat(d, d2);
export const empty = localDuration.empty;
export const inverse = localDuration.inverse;
export const add = (d) => (t) => localDuration.add(t, d);
export const difference = (t2) => (t) => localDuration.difference(t, t2);
export const isoStringAsLocalDuration = new Prism((s) => Opt.tryCatch(() => Temporal.Duration.from(s)), (s) => s.toString()).composeIso(isoLocalDuration.reverse());
export const localDurationT = t.string.pipe(ioTsPrism("LocalDuration", 
// @TODO
(a) => a instanceof Temporal.Duration, isoStringAsLocalDuration));
export const MonoidSum = {
    empty,
    concat: (x, y) => concat(x)(y),
};
