/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ApiSettings interface.
 */
export function instanceOfApiSettings(value) {
    if (!("importBodyData" in value) || value.importBodyData === undefined)
        return false;
    if (!("importActivities" in value) || value.importActivities === undefined)
        return false;
    return true;
}
export function ApiSettingsFromJSON(json) {
    return ApiSettingsFromJSONTyped(json, false);
}
export function ApiSettingsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        importBodyData: json.importBodyData,
        importActivities: json.importActivities,
    };
}
export function ApiSettingsToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        importBodyData: value.importBodyData,
        importActivities: value.importActivities,
    };
}
