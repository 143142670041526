/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TimeslotApi interface.
 */
export function instanceOfTimeslotApi(value) {
    if (!("time" in value) || value.time === undefined)
        return false;
    if (!("outdated" in value) || value.outdated === undefined)
        return false;
    if (!("recoveryStatuses" in value) || value.recoveryStatuses === undefined)
        return false;
    return true;
}
export function TimeslotApiFromJSON(json) {
    return TimeslotApiFromJSONTyped(json, false);
}
export function TimeslotApiFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        time: new Date(json.time),
        outdated: json.outdated,
        calories: json.calories == null ? undefined : json.calories,
        carbonhydrate: json.carbonhydrate == null ? undefined : json.carbonhydrate,
        protein: json.protein == null ? undefined : json.protein,
        fatSaturated: json.fatSaturated == null ? undefined : json.fatSaturated,
        fatUnsaturated: json.fatUnsaturated == null ? undefined : json.fatUnsaturated,
        glx: json.glx == null ? undefined : json.glx,
        recoveryStatuses: json.recoveryStatuses,
    };
}
export function TimeslotApiToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        time: value.time.toISOString(),
        outdated: value.outdated,
        calories: value.calories,
        carbonhydrate: value.carbonhydrate,
        protein: value.protein,
        fatSaturated: value.fatSaturated,
        fatUnsaturated: value.fatUnsaturated,
        glx: value.glx,
        recoveryStatuses: value.recoveryStatuses,
    };
}
