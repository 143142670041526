/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UnitPreferencesFromJSON, UnitPreferencesToJSON, } from "./UnitPreferences";
import { UserFromJSON, UserToJSON } from "./User";
/**
 * Check if a given object implements the WizardData interface.
 */
export function instanceOfWizardData(value) {
    if (!("user" in value) || value.user === undefined)
        return false;
    if (!("activityRecommendations" in value) ||
        value.activityRecommendations === undefined)
        return false;
    if (!("nutritionRecommendations" in value) ||
        value.nutritionRecommendations === undefined)
        return false;
    return true;
}
export function WizardDataFromJSON(json) {
    return WizardDataFromJSONTyped(json, false);
}
export function WizardDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        units: json.units == null ? undefined : UnitPreferencesFromJSON(json.units),
        user: UserFromJSON(json.user),
        activityRecommendations: json.activityRecommendations,
        nutritionRecommendations: json.nutritionRecommendations,
    };
}
export function WizardDataToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        units: UnitPreferencesToJSON(value.units),
        user: UserToJSON(value.user),
        activityRecommendations: value.activityRecommendations,
        nutritionRecommendations: value.nutritionRecommendations,
    };
}
