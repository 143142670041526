/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ReferralCode interface.
 */
export function instanceOfReferralCode(value) {
    if (!("userId" in value) || value.userId === undefined)
        return false;
    if (!("code" in value) || value.code === undefined)
        return false;
    if (!("usedBy" in value) || value.usedBy === undefined)
        return false;
    return true;
}
export function ReferralCodeFromJSON(json) {
    return ReferralCodeFromJSONTyped(json, false);
}
export function ReferralCodeFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        userId: json.userId,
        code: json.code,
        usedBy: json.usedBy,
    };
}
export function ReferralCodeToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        userId: value.userId,
        code: value.code,
        usedBy: value.usedBy,
    };
}
