/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CoachTaskActionFromJSON, CoachTaskActionToJSON, } from "./CoachTaskAction";
import { ImportanceFromJSON, ImportanceToJSON, } from "./Importance";
/**
 * Check if a given object implements the CoachTask interface.
 */
export function instanceOfCoachTask(value) {
    if (!("isTodayTask" in value) || value.isTodayTask === undefined)
        return false;
    if (!("type" in value) || value.type === undefined)
        return false;
    if (!("refId" in value) || value.refId === undefined)
        return false;
    if (!("importance" in value) || value.importance === undefined)
        return false;
    if (!("description" in value) || value.description === undefined)
        return false;
    if (!("action" in value) || value.action === undefined)
        return false;
    return true;
}
export function CoachTaskFromJSON(json) {
    return CoachTaskFromJSONTyped(json, false);
}
export function CoachTaskFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        isTodayTask: json.isTodayTask,
        type: json.type,
        refId: json.refId,
        importance: ImportanceFromJSON(json.importance),
        description: json.description,
        action: CoachTaskActionFromJSON(json.action),
        id: json.id == null ? undefined : json.id,
        dismissed: json.dismissed == null ? undefined : new Date(json.dismissed),
        completed: json.completed == null ? undefined : new Date(json.completed),
    };
}
export function CoachTaskToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        isTodayTask: value.isTodayTask,
        type: value.type,
        refId: value.refId,
        importance: ImportanceToJSON(value.importance),
        description: value.description,
        action: CoachTaskActionToJSON(value.action),
        id: value.id,
        dismissed: value.dismissed == null
            ? undefined
            : value.dismissed.toISOString(),
        completed: value.completed == null
            ? undefined
            : value.completed.toISOString(),
    };
}
