/* eslint @typescript-eslint/no-unsafe-argument: "warn"
   -- TODO eslint thinks some types are `any` even though they are not. */

import * as Apply from "fp-ts/es6/Apply"
import * as Bool from "fp-ts/es6/boolean"
import { flow, pipe } from "fp-ts/es6/function"
import * as F from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Semigroup from "fp-ts/es6/Semigroup"
import * as These from "fp-ts/es6/These"
import { Getter, Iso, Prism } from "monocle-ts"
import {
  currentTimeZone,
  dateAsTime,
  dayOfTime,
  isoStringAsLocalTimeOfDay,
  isoStringAsLocalTimeOfDayDuration,
  isoStringAsTimeOfDay,
  localTimeFromDayAndLocalTimeOfDay,
  LocalTimeOfDay,
  startOfDay,
  timeAsLocalTime,
  timeOfDayOfTime,
  today,
} from "time-ts/es6"
import { timeOfDayAsLocalTimeOfDayInCurrentEnv } from "time-ts/es6/Local/TimeOfDay"
import {
  bisequencePair,
  Capitalise,
  capitalise,
  Dimension,
  mapPair,
  nullablePrism,
  numberAsValidNumber,
  OptionValue,
  prismToGetter,
  stringAsEmailAddress,
  stringAsNumber,
  stringAsUrl,
  unsafeFromSome,
} from "@fitnesspilot/data-common"

import * as API from "@fitnesspilot/api-combined"
import { DayOfWeek } from "@fitnesspilot/data-common/dist/DayOfWeek"
import {
  canonicaliseDimValue,
  UnitLengthDistance,
  UnitLengthHeight,
  UnitMass,
  UnitSelection,
  valueInUnit,
} from "@fitnesspilot/data-common/dist/dimensionalAnalysis"
import * as BodyComposition from "@fitnesspilot/data-human-body/dist/bodyComposition"
import * as HumanBody from "@fitnesspilot/data-human-body/dist/humanBody"
import { Sex } from "@fitnesspilot/data-human-body/dist/sex"

import { AboutMeAnswers } from "../AboutMeAnswers"
import { GoogleFit } from "../GoogleFit"
import * as Habits from "../Habits"
import { InviteCode } from "../InviteCode"
import * as Job from "../Job"
import { ReferralCode } from "../ReferralCode"
import * as Sleep from "../Sleep"
import * as User from "../User"
import * as Work from "../Work"
import { GoogleAuthCode } from "./actions"

const almostIsoStringAsLocalTimeOfDayFromZ = () =>
  // This is _only_ an Iso in the context of the prism with which it is composed
  new Iso<string, string>(
    (t) => `${t}Z`,
    (t) => t.replace(/Z$/, ""),
  )
    .composePrism(isoStringAsTimeOfDay)
    .composeIso(timeOfDayAsLocalTimeOfDayInCurrentEnv())

export const unitSelection = new Iso<API.UnitPreferences, UnitSelection>(
  ({ height, mass, distance }) => ({
    mass: mass === "pound" ? UnitMass.pound : UnitMass.kilogram,
    lengthHeight:
      height === "feetAndInches"
        ? UnitLengthHeight.footAndInch
        : UnitLengthHeight.centimetre,
    lengthDistance:
      distance === "miles"
        ? UnitLengthDistance.mile
        : UnitLengthDistance.kilometre,
  }),
  ({ mass, lengthHeight, lengthDistance }) => ({
    mass: mass === UnitMass.pound ? API.UnitMass.Pound : API.UnitMass.Kilogram,
    height:
      lengthHeight === UnitLengthHeight.footAndInch
        ? API.UnitHeight.FeetAndInches
        : API.UnitHeight.Centimetres,
    distance:
      lengthDistance === UnitLengthDistance.mile
        ? API.UnitDistance.Miles
        : API.UnitDistance.Kilometres,
  }),
)

export const job = new Iso<API.Job, Job.Job>(
  (s) => ({
    id: pipe(s.id, Job.stringAsJobId.getOption, unsafeFromSome),
    name: s.name ?? "",
    instructions: Opt.none, // TODO
    activity: {
      physical: pipe(
        s.physicalActivity,
        Opt.fromNullable,
        Opt.getOrElse(() => 0),
      ),
      mental: pipe(
        s.mentalActivity,
        Opt.fromNullable,
        Opt.getOrElse(() => 0),
      ),
    },
  }),
  (s) => ({
    id: pipe(s.id, Job.stringAsJobId.reverseGet),
    name: s.name,
    isCustom: false, // TODO
    physicalActivity: s.activity.physical,
    mentalActivity: s.activity.mental,
  }),
)

export const schedule = new Iso<
  API.UserSleep["schedule"],
  Rec.ReadonlyRecord<
    DayOfWeek,
    Opt.Option<readonly [LocalTimeOfDay, LocalTimeOfDay]>
  >
>(
  (s) =>
    pipe(
      DayOfWeek,
      Rec.map((day) =>
        pipe(
          s[day],
          Opt.fromNullable,
          Opt.map((a) =>
            pipe(
              [pipe(a[0], Opt.fromNullable), pipe(a[1], Opt.fromNullable)],
              mapPair(
                Opt.chain(almostIsoStringAsLocalTimeOfDayFromZ().getOption),
              ),
            ),
          ),
          Opt.getOrElse<
            readonly [Opt.Option<LocalTimeOfDay>, Opt.Option<LocalTimeOfDay>]
          >(() => [Opt.none, Opt.none]),
          bisequencePair(Opt.Apply),
        ),
      ),
    ),
  (s) =>
    pipe(
      DayOfWeek,
      Rec.map((day) =>
        pipe(
          s[day],
          Opt.fold(
            () => undefined as [string, string] | undefined,
            (a) =>
              mapPair(almostIsoStringAsLocalTimeOfDayFromZ().reverseGet)(a) as [
                string,
                string,
              ],
          ),
        ),
      ),
    ),
)

export const activityJob = new Getter<API.ActivityJob, Job.Job>((s) => ({
  id: pipe(s.activityId, Job.stringAsJobId.getOption, unsafeFromSome),
  name: s.title,
  instructions: pipe(s.instructions, Opt.fromNullable),
  activity: {
    physical: 0,
    mental: 0,
  },
}))

const habit = <api extends string | number, local extends string>(
  api: Record<`NUMBER_${0 | 1 | 2}`, api>,
  number0: local,
  number1: local,
  number2: local,
) =>
  new Iso(
    (s: api): local =>
      ({
        [api.NUMBER_0]: number0,
        [api.NUMBER_1]: number1,
        [api.NUMBER_2]: number2,
      })[s],
    (s: local) =>
      ({
        [number0]: api.NUMBER_0,
        [number1]: api.NUMBER_1,
        [number2]: api.NUMBER_2,
      })[s],
  )

const sex = new Iso<API.Sex, Sex>(
  (s) =>
    ({
      [API.Sex.F]: Sex.female,
      [API.Sex.M]: Sex.male,
    })[s],
  (s) => ({ [Sex.female]: API.Sex.F, [Sex.male]: API.Sex.M })[s],
)

export const userData = (units: UnitSelection) =>
  new Iso<
    API.ApiUserData,
    {
      body: HumanBody.HumanBody
      work: Opt.Option<Work.Work>
      sleep: Opt.Option<Sleep.Sleep>
      habits: Habits.Habits
    }
  >(
    (s) => ({
      body: {
        mass: pipe(
          s.bodyData?.mass,
          nullablePrism<number | null | undefined>()
            .composePrism(numberAsValidNumber)
            .composeIso(valueInUnit(Dimension.mass, units.mass))
            .composeIso(canonicaliseDimValue(Dimension.mass, units.mass))
            .getOption,
        ),
        height: pipe(
          s.bodyData?.height,
          nullablePrism<number | null | undefined>()
            .composePrism(numberAsValidNumber)
            .composeIso(valueInUnit(Dimension.lengthHeight, units.lengthHeight))
            .composeIso(
              canonicaliseDimValue(Dimension.lengthHeight, units.lengthHeight),
            ).getOption,
        ),
        birthDate: pipe(
          s.birthDate,
          nullablePrism<typeof s.birthDate>()
            .composePrism(dateAsTime)
            .composeLens(dayOfTime).getOption,
        ),
        sex: pipe(
          s.sex,
          nullablePrism<typeof s.sex>().composeIso(sex).getOption,
        ),
        bodyComposition: pipe(
          These.fromOptions(
            These.fromOptions(
              Opt.fromNullable(s.bodyData?.waterShare),
              Opt.fromNullable(s.bodyData?.fatShare),
            ),
            Opt.fromNullable(s.bodyData?.fatFreeShare),
          ),
          Opt.map(
            (v): BodyComposition.BodyComposition => ({
              water: pipe(
                v,
                These.getLeft,
                Opt.chain(These.getLeft),
                Opt.toUndefined,
              ),
              adipose: pipe(
                v,
                These.getLeft,
                Opt.chain(These.getRight),
                Opt.toUndefined,
              ),
              muscle: pipe(v, These.getRight, Opt.toUndefined),
            }),
          ),
        ),
      },
      work: Apply.sequenceS(Opt.Apply)({
        job: pipe(s.work?.job, Opt.fromNullable, Opt.map(job.get)),
        mayEatAtWork: pipe(
          s.work?.mayEatAtWork,
          Opt.fromNullable,
          Opt.getOrElse(() => false),
          Opt.some,
        ),
        lunchtime: pipe(
          s.work?.lunchtime,
          Opt.fromNullable,
          Opt.map(({ duration, within }) => ({
            duration: pipe(
              duration,
              Opt.fromNullable,
              Opt.chain(isoStringAsLocalTimeOfDayDuration.getOption),
            ),
            within: pipe(
              within as [string, string],
              mapPair(
                nullablePrism<(typeof within)[0]>().composePrism(
                  almostIsoStringAsLocalTimeOfDayFromZ(),
                ).getOption,
              ),
              bisequencePair(Opt.Apply),
            ),
          })),
          Opt.chain(Apply.sequenceS(Opt.Apply)),
        ),
        time: pipe(s.work?.schedule, Opt.fromNullable, Opt.map(schedule.get)),
      }),
      sleep: Apply.sequenceS(Opt.Apply)({
        time: pipe(
          s.sleep?.schedule,
          Opt.fromNullable,
          Opt.map(schedule.get),
          Opt.chain(Rec.sequence(Opt.Applicative)),
        ),
      }),
      habits: {
        activity: pipe(
          s.habits?.activityLevel,
          nullablePrism<API.HabitActivity | undefined>().composeIso(
            habit(
              API.HabitActivity,
              Habits.ActivityHabits.lazy,
              Habits.ActivityHabits.moderate,
              Habits.ActivityHabits.active,
            ),
          ).getOption,
          Opt.getOrElse(
            (): Habits.ActivityHabits => Habits.ActivityHabits.moderate,
          ),
        ),
        drinking: pipe(
          s.habits?.drinkingWater,
          nullablePrism<API.HabitFood | undefined>().composeIso(
            habit(
              API.HabitFood,
              Habits.DrinkingHabits.grosslyInsufficient,
              Habits.DrinkingHabits.insufficient,
              Habits.DrinkingHabits.sufficient,
            ),
          ).getOption,
          Opt.getOrElse(
            (): Habits.DrinkingHabits => Habits.DrinkingHabits.sufficient,
          ),
        ),
        eating: pipe(
          s.habits?.eating,
          nullablePrism<API.HabitFood | undefined>().composeIso(
            habit(
              API.HabitFood,
              Habits.EatingHabits.untilSomewhatHungry,
              Habits.EatingHabits.untilNotHungry,
              Habits.EatingHabits.untilFull,
            ),
          ).getOption,
          Opt.getOrElse(
            (): Habits.EatingHabits => Habits.EatingHabits.untilSomewhatHungry,
          ),
        ),
        consumption: {
          smoking: pipe(
            s.habits?.smoking,
            nullablePrism<API.HabitAddiction | undefined>().composeIso(
              habit(
                API.HabitAddiction,
                Habits.ConsumptionLevel.never,
                Habits.ConsumptionLevel.occasionally,
                Habits.ConsumptionLevel.frequently,
              ),
            ).getOption,
            Opt.getOrElse(
              (): Habits.ConsumptionLevel => Habits.ConsumptionLevel.never,
            ),
          ),
          alcohol: pipe(
            s.habits?.drinkingAlcohol,
            nullablePrism<API.HabitAddiction | undefined>().composeIso(
              habit(
                API.HabitAddiction,
                Habits.ConsumptionLevel.never,
                Habits.ConsumptionLevel.occasionally,
                Habits.ConsumptionLevel.frequently,
              ),
            ).getOption,
            Opt.getOrElse(
              (): Habits.ConsumptionLevel =>
                Habits.ConsumptionLevel.occasionally,
            ),
          ),
        },
      },
    }),
    ({ body, work, sleep, habits }) => ({
      birthDate: pipe(
        body.birthDate,
        Opt.map(flow(startOfDay, dateAsTime.reverseGet)),
        Opt.toUndefined,
      ),
      sex: pipe(body.sex, Opt.map(sex.reverseGet), Opt.toUndefined),
      bodyData: {
        mass: pipe(
          body.mass,
          Opt.map(
            numberAsValidNumber
              .composeIso(valueInUnit(Dimension.mass, units.mass))
              .composeIso(canonicaliseDimValue(Dimension.mass, units.mass))
              .reverseGet,
          ),
          Opt.toUndefined,
        ),
        height: pipe(
          body.height,
          Opt.map(
            numberAsValidNumber
              .composeIso(
                valueInUnit(Dimension.lengthHeight, units.lengthHeight),
              )
              .composeIso(
                canonicaliseDimValue(
                  Dimension.lengthHeight,
                  units.lengthHeight,
                ),
              ).reverseGet,
          ),
          Opt.toUndefined,
        ),
        waterShare: pipe(
          body.bodyComposition,
          Opt.map((b) => b.water),
          Opt.toUndefined,
        ),
        fatShare: pipe(
          body.bodyComposition,
          Opt.map((b) => b.adipose),
          Opt.toUndefined,
        ),
        fatFreeShare: pipe(
          body.bodyComposition,
          Opt.map((b) => b.muscle),
          Opt.toUndefined,
        ),
      },
      work: pipe(
        work,
        Opt.map(
          (work): API.UserWork => ({
            job: pipe(work.job, job.reverseGet),
            mayEatAtWork: work.mayEatAtWork,
            lunchtime: pipe(work.lunchtime, ({ duration, within }) => ({
              duration: pipe(
                duration,
                isoStringAsLocalTimeOfDayDuration.reverseGet,
              ),
              within: [
                pipe(
                  within[0],
                  almostIsoStringAsLocalTimeOfDayFromZ().reverseGet,
                ),
                pipe(
                  within[1],
                  almostIsoStringAsLocalTimeOfDayFromZ().reverseGet,
                ),
              ],
            })),
            schedule: pipe(work.time, schedule.reverseGet),
          }),
        ),
        Opt.toUndefined,
      ),
      sleep: pipe(
        sleep,
        Opt.map(
          (sleep): API.UserSleep => ({
            schedule: pipe(sleep.time, Rec.map(Opt.some), schedule.reverseGet),
          }),
        ),
        Opt.toUndefined,
      ),
      habits: {
        activityLevel: pipe(
          habits.activity,
          habit(
            API.HabitActivity,
            Habits.ActivityHabits.lazy,
            Habits.ActivityHabits.moderate,
            Habits.ActivityHabits.active,
          ).reverseGet,
        ),
        drinkingWater: pipe(
          habits.drinking,
          habit(
            API.HabitFood,
            Habits.DrinkingHabits.grosslyInsufficient,
            Habits.DrinkingHabits.insufficient,
            Habits.DrinkingHabits.sufficient,
          ).reverseGet,
        ),
        eating: pipe(
          habits.eating,
          habit(
            API.HabitFood,
            Habits.EatingHabits.untilSomewhatHungry,
            Habits.EatingHabits.untilNotHungry,
            Habits.EatingHabits.untilFull,
          ).reverseGet,
        ),
        smoking: pipe(
          habits.consumption.smoking,
          habit(
            API.HabitAddiction,
            Habits.ConsumptionLevel.never,
            Habits.ConsumptionLevel.occasionally,
            Habits.ConsumptionLevel.frequently,
          ).reverseGet,
        ),
        drinkingAlcohol: pipe(
          habits.consumption.alcohol,
          habit(
            API.HabitAddiction,
            Habits.ConsumptionLevel.never,
            Habits.ConsumptionLevel.occasionally,
            Habits.ConsumptionLevel.frequently,
          ).reverseGet,
        ),
      },
    }),
  )

export const user = new Iso<API.UserAccount, User.User>(
  (s) => ({
    id: pipe(
      s.uid,
      Opt.fromNullable,
      Opt.chain(User.stringAsUserId.getOption),
      unsafeFromSome,
    ),
    created: pipe(s.created, Opt.fromNullable, Opt.chain(dateAsTime.getOption)),
    email: pipe(
      s.email,
      Opt.fromNullable,
      Opt.chain(stringAsEmailAddress.getOption),
      unsafeFromSome,
    ),
    person: {
      image: pipe(
        s.profilePicture,
        Opt.fromNullable,
        Opt.chain(stringAsUrl.getOption),
      ),
      givenName: pipe(s.firstName, Opt.fromNullable),
      familyName: pipe(s.lastName, Opt.fromNullable),
      name: pipe(s.nickname, Opt.fromNullable),
    },
    hasCompletedSetup: pipe(
      s.setupIncomplete,
      Opt.fromNullable,
      Opt.map((v) => !v),
      Opt.getOrElse(F.constTrue),
    ),
    applyDemoData: pipe(
      s.applyDemoData,
      Opt.fromNullable,
      Opt.getOrElse(F.constFalse),
    ),
    googleApi: {
      isEnabled: pipe(
        s.googleApiActive,
        Opt.fromNullable,
        Opt.getOrElse(F.constFalse),
      ),
      scopes: pipe(
        s.googleApiScopes,
        Opt.fromNullable,
        Opt.getOrElse((): Array<string> => []),
      ),
    },
  }),
  (a) => ({
    uid: User.stringAsUserId.reverseGet(a.id),
    created: pipe(a.created, Opt.map(dateAsTime.reverseGet), Opt.toUndefined),
    email: stringAsEmailAddress.reverseGet(a.email),
    profilePicture: pipe(
      a.person.image,
      Opt.map(stringAsUrl.reverseGet),
      Opt.toUndefined,
    ),
    firstName: pipe(a.person.givenName, Opt.toUndefined),
    lastName: pipe(a.person.familyName, Opt.toUndefined),
    nickname: pipe(a.person.name, Opt.toUndefined),
    setupIncomplete: pipe(
      a.hasCompletedSetup,
      Bool.fold(
        () => Opt.some(true),
        () => Opt.none,
      ),
      Opt.toUndefined,
    ),
    applyDemoData: pipe(
      a.applyDemoData,
      Bool.fold(
        () => Opt.some(true),
        () => Opt.none,
      ),
      Opt.toUndefined,
    ),
    googleApiActive: pipe(
      a.googleApi.isEnabled,
      Bool.fold(
        () => Opt.some(true),
        () => Opt.none,
      ),
      Opt.toUndefined,
    ),
    googleApiScopes: pipe(
      a.googleApi.scopes,
      NonEmpty.fromReadonlyArray,
      Opt.map(Arr.toArray),
      Opt.toUndefined,
    ),
  }),
)

export const aboutMeAnswers = new Getter<
  API.AboutMeSummaryResponse,
  AboutMeAnswers
>(({ answersProvided }) =>
  pipe(
    answersProvided?.split(";"),
    Opt.fromNullable,
    Opt.map(Arr.map(stringAsNumber.getOption)),
    Opt.map(Opt.sequenceArray),
    Opt.flatten,
    Opt.map((as) => ({
      at: Arr.head(as),
      of: Arr.lookup(1)(as),
    })),
    Opt.getOrElse(() => ({
      at: Opt.none as Opt.Option<number>,
      of: Opt.none as Opt.Option<number>,
    })),
    ({ at, of }) => ({
      at: Opt.getOrElse(() => 0)(at),
      of: Opt.getOrElse(() => 0)(of),
    }),
  ),
)

export const googleAuthCodeToApi = new Getter<
  GoogleAuthCode,
  API.AuthorizationCodeRequest
>((s) => ({
  authorizationCode: s.code,
  scopes: pipe(s.scopes, Arr.toArray),
  redirectUri: s.redirectUri,
}))

export const googleFit = new Iso<API.ApiSettings, GoogleFit>(
  ({ importActivities, importBodyData }) => ({
    importActivities: pipe(
      importActivities,
      Opt.fromNullable,
      Opt.getOrElse(F.constFalse),
    ),
    importBodyData: pipe(
      importBodyData,
      Opt.fromNullable,
      Opt.getOrElse(F.constFalse),
    ),
  }),
  (a) => a,
)

export const referralCode = new Iso<API.ReferralCode, ReferralCode>(
  ({ userId, code, usedBy }) => ({
    userId: pipe(
      userId,
      Opt.fromNullable,
      Opt.chain(User.stringAsUserId.getOption),
      unsafeFromSome,
    ),
    code: pipe(code, Opt.fromNullable, unsafeFromSome),
    usedBy: pipe(
      usedBy,
      Opt.fromNullable,
      Opt.fold(
        () => [],
        Arr.map(flow(User.stringAsUserId.getOption, unsafeFromSome)),
      ),
    ),
  }),
  ({ userId, code, usedBy }) => ({
    userId: pipe(userId, User.stringAsUserId.reverseGet),
    code,
    usedBy: pipe(usedBy, Arr.map(User.stringAsUserId.reverseGet), Arr.toArray),
  }),
)

export const inviteCode = new Iso<API.InviteCode, InviteCode>(
  ({ code, usedBy }) => ({
    code: pipe(code, Opt.fromNullable, unsafeFromSome),
    usedBy: pipe(
      usedBy,
      Opt.fromNullable,
      Opt.chain(User.stringAsUserId.getOption),
    ),
  }),
  ({ code, usedBy }) => ({
    code,
    usedBy: pipe(
      usedBy,
      Opt.fold(() => undefined, User.stringAsUserId.reverseGet),
    ),
  }),
)
