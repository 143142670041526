/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import { ActivityMuscleInfoFromJSON, ActivityMuscleInfoToJSON, } from "./ActivityMuscleInfo";
import { ModelSetFromJSON, ModelSetToJSON, } from "./ModelSet";
/**
 * Check if a given object implements the ActivityExecution interface.
 */
export function instanceOfActivityExecution(value) {
    if (!("id" in value) || value.id === undefined)
        return false;
    if (!("eventTime" in value) || value.eventTime === undefined)
        return false;
    if (!("alignment" in value) || value.alignment === undefined)
        return false;
    if (!("activityId" in value) || value.activityId === undefined)
        return false;
    if (!("intensity" in value) || value.intensity === undefined)
        return false;
    return true;
}
export function ActivityExecutionFromJSON(json) {
    return ActivityExecutionFromJSONTyped(json, false);
}
export function ActivityExecutionFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        id: json.id,
        eventTime: new Date(json.eventTime),
        alignment: json.alignment,
        muscles: json.muscles == null
            ? undefined
            : mapValues(json.muscles, ActivityMuscleInfoFromJSON),
        activityId: json.activityId,
        duration: json.duration == null ? undefined : json.duration,
        distance: json.distance == null ? undefined : json.distance,
        sets: json.sets == null
            ? undefined
            : json.sets.map(ModelSetFromJSON),
        muscleGroupIds: json.muscleGroupIds == null ? undefined : json.muscleGroupIds,
        intensity: json.intensity,
    };
}
export function ActivityExecutionToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        id: value.id,
        eventTime: value.eventTime.toISOString(),
        alignment: value.alignment,
        muscles: value.muscles == null
            ? undefined
            : mapValues(value.muscles, ActivityMuscleInfoToJSON),
        activityId: value.activityId,
        duration: value.duration,
        distance: value.distance,
        sets: value.sets == null
            ? undefined
            : value.sets.map(ModelSetToJSON),
        muscleGroupIds: value.muscleGroupIds,
        intensity: value.intensity,
    };
}
