/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobFromJSON, JobToJSON } from "./Job";
import { LunchtimeFromJSON, LunchtimeToJSON, } from "./Lunchtime";
import { UserSleepScheduleFromJSON, UserSleepScheduleToJSON, } from "./UserSleepSchedule";
/**
 * Check if a given object implements the UserWork interface.
 */
export function instanceOfUserWork(value) {
    if (!("schedule" in value) || value.schedule === undefined)
        return false;
    if (!("job" in value) || value.job === undefined)
        return false;
    if (!("mayEatAtWork" in value) || value.mayEatAtWork === undefined)
        return false;
    return true;
}
export function UserWorkFromJSON(json) {
    return UserWorkFromJSONTyped(json, false);
}
export function UserWorkFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        schedule: UserSleepScheduleFromJSON(json.schedule),
        job: JobFromJSON(json.job),
        lunchtime: json.lunchtime == null ? undefined : LunchtimeFromJSON(json.lunchtime),
        mayEatAtWork: json.mayEatAtWork,
    };
}
export function UserWorkToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        schedule: UserSleepScheduleToJSON(value.schedule),
        job: JobToJSON(value.job),
        lunchtime: LunchtimeToJSON(value.lunchtime),
        mayEatAtWork: value.mayEatAtWork,
    };
}
