/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WizardDataToJSON, } from "../models/index";
import * as runtime from "../runtime";
/**
 *
 */
export class ApplyWizardApi extends runtime.BaseAPI {
    /**
     * Handles POST requests for UserManagement.Controllers.ApplyWizardController.
     */
    async userManagementApiUsersUserIdWizardPostRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling userManagementApiUsersUserIdWizardPost().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/wizard`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: WizardDataToJSON(requestParameters.wizardData),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Handles POST requests for UserManagement.Controllers.ApplyWizardController.
     */
    async userManagementApiUsersUserIdWizardPost(requestParameters, initOverrides) {
        await this.userManagementApiUsersUserIdWizardPostRaw(requestParameters, initOverrides);
    }
}
