/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UnitOptionsFromJSON, UnitPreferencesFromJSON, UnitPreferencesToJSON, } from "../models/index";
import * as runtime from "../runtime";
/**
 *
 */
export class UnitsApi extends runtime.BaseAPI {
    /**
     */
    async userManagementApiUsersUserIdSettingsUnitsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling userManagementApiUsersUserIdSettingsUnitsGet().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/settings/units`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UnitPreferencesFromJSON(jsonValue));
    }
    /**
     */
    async userManagementApiUsersUserIdSettingsUnitsGet(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdSettingsUnitsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userManagementApiUsersUserIdSettingsUnitsOptionsRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling userManagementApiUsersUserIdSettingsUnitsOptions().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/settings/units`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "OPTIONS",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UnitOptionsFromJSON(jsonValue));
    }
    /**
     */
    async userManagementApiUsersUserIdSettingsUnitsOptions(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdSettingsUnitsOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userManagementApiUsersUserIdSettingsUnitsPutRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling userManagementApiUsersUserIdSettingsUnitsPut().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/settings/units`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: UnitPreferencesToJSON(requestParameters.unitPreferences),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UnitPreferencesFromJSON(jsonValue));
    }
    /**
     */
    async userManagementApiUsersUserIdSettingsUnitsPut(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdSettingsUnitsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
