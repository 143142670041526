import * as Arr_ from "fp-ts/es6/Array"
import * as Bool from "fp-ts/es6/boolean"
import * as E from "fp-ts/es6/Either"
import { constant, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as St from "fp-ts/es6/ReadonlySet"
import * as Tuple from "fp-ts/es6/ReadonlyTuple"
import * as T from "fp-ts/es6/Task"
import * as TE from "fp-ts/es6/TaskEither"
import * as O from "fp-ts-reactive/es6/Observable"
import { fromTraversable, Index, Optional, Prism } from "monocle-ts"
import { prismPositive } from "newtype-ts/es6/Positive"
import {
  _hour,
  _minute,
  duration,
  isoStringAsDuration,
  mkLocalDuration,
  mkTimeOfDayWrapped,
  now,
  time,
  timeOfDayOfTime,
  zonedDuration,
  zonedStartOfTodayInCurrentTimeZone,
  zonedTimeToTime,
} from "time-ts/es6"
import {
  arrayToObservable,
  arrTrav,
  bisequencePair,
  composeLensIndex,
  inspect,
  prismToGetter,
  unsafeFromSome,
  withLatestFrom,
} from "@fitnesspilot/data-common"

import { EventChangeType } from "@fitnesspilot/api-combined"
import * as API from "@fitnesspilot/api-combined"
import * as WithId from "@fitnesspilot/data-activity/dist/activity/WithId"
import {
  _ActivityInstanceNonGroup,
  ActivityInstanceNonGroup,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import api, {
  Action as APIAction,
  AnyAPIError,
  API as APIType,
  ParentState as APIParentState,
  setAccountReady,
  setToken,
} from "@fitnesspilot/data-api"
import { stringAsMuscleId } from "@fitnesspilot/data-human-body/dist/Muscle"
import * as UserData from "@fitnesspilot/data-user"
import * as PROTO from "@fitnesspilot/proto"

import * as Event from "../calendar/Event"
import * as EventOrRecommendation from "../calendar/EventOrRecommendation"
import * as EventsOrRecommendations from "../calendar/EventsOrRecommendations"
import { WorkoutVideo } from "../video/WorkoutVideo"
import * as actions from "./actions"
import { Action } from "./actions"
import * as AddActivity from "./AddActivity"
import * as apiToState from "./api"
import * as CalendarSelection from "./CalendarSelection"
import * as EditingEvent from "./EditingEvent"
import * as protoToState from "./proto"
import * as selectors from "./selectors"
import { initialEditingEvent, ParentState } from "./state"

import {
  LOCATION_CHANGE,
  push as pushHistory,
  RouterActions,
} from "redux-first-history"
import { combineEpics, Epic } from "redux-observable"
import { debounceTime } from "rxjs/operators"
import { isActionOf, isOfType } from "typesafe-actions"
import * as ytSearch from "youtube-search"

const _eventsIndex = composeLensIndex(
  selectors.eventsOrRecommendations,
  Index.fromAt(EventsOrRecommendations._eventsAt),
)

type ParentAPIState = ParentState & APIParentState

type AnEpic = Epic<
  Action | APIAction | UserData.Action | RouterActions,
  Action | APIAction | RouterActions,
  ParentAPIState
>

export const setTokenFlow: AnEpic = (
  action$,
  // state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(setToken)),
    O.map((a) => a.payload),
    O.map(
      Opt.fold(
        () => [actions.resetState()],
        () => [],
      ),
    ),
    O.chain(arrayToObservable),
  )

export const setAccountReadyFlow: AnEpic = (
  action$,
  // state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(setAccountReady)),
    O.map((a) => a.payload),
    O.map(
      Bool.fold(
        () => [],
        () =>
          pipe(
            actions.fetchEventsOrRecommendationsBetweenIfNeeded({
              between: [
                pipe(
                  zonedStartOfTodayInCurrentTimeZone,
                  IO.map(zonedTimeToTime.get),
                )(),
                pipe(
                  zonedStartOfTodayInCurrentTimeZone,
                  IO.map(
                    flow(
                      (t) => zonedDuration.add(t, mkLocalDuration({ days: 1 })),
                      zonedTimeToTime.get,
                    ),
                  ),
                )(),
              ],
            }),
            Arr.of,
          ),
      ),
    ),
    O.chain(arrayToObservable),
  )

export const fetchEventsOrRecommendationsBetweenFlow: AnEpic = (
  action$,
  state$,
) =>
  pipe(
    action$,
    O.filter(
      isActionOf(actions.fetchEventsOrRecommendationsBetweenAsync.request),
    ),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([action, api]) =>
      pipe(
        api.events.getEventsBetween({
          start: Opt.some(action.payload.between[0]),
          end: Opt.some(action.payload.between[1]),
          includeTimeslots: true,
        })("me"),
        TE.map(({ events, timeslots }) => ({
          events: pipe(
            events,
            Opt.fromNullable,
            Opt.getOrElse(() => [] as Array<API.Event>),
          ),
          timeslots: pipe(
            timeslots,
            Opt.fromNullable,
            Opt.getOrElse(() => [] as Array<API.TimeslotApi>),
          ),
        })),
        TE.fold<
          AnyAPIError,
          { events: Array<API.Event>; timeslots: Array<API.TimeslotApi> },
          ReadonlyArray<Action>
        >(
          flow(
            actions.fetchEventsOrRecommendationsBetweenAsync.failure,
            Arr.of,
            T.of,
          ),
          flow(
            ({ events, timeslots }) => [
              pipe(
                {
                  between: action.payload.between,
                  eventsOrRecommendations: pipe(
                    events,
                    arrTrav<API.Event>().composeIso(apiToState.event).asFold()
                      .getAll,
                  ),
                  timeslots: pipe(
                    timeslots,
                    arrTrav<API.TimeslotApi>().composeGetter(
                      apiToState.timeslot,
                    ).getAll,
                  ),
                },
                actions.fetchEventsOrRecommendationsBetweenAsync.success,
              ),
            ],
            T.of,
          ),
        ),
        O.fromTask,
        O.chain(arrayToObservable),
      ),
    ),
  )

const hasSubRange =
  <a>(ord: Ord.Ord<a>, inner: readonly [a, a]) =>
  (outer: readonly [a, a]) =>
    pipe(inner, Arr.every(Ord.between(ord)(outer[0], outer[1])))

export const fetchEventsOrRecommendationsBetweenIfNeededFlow: AnEpic = (
  action$,
  state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.fetchEventsOrRecommendationsBetweenIfNeeded)),
    O.map((act) => act.payload),
    withLatestFrom(state$),
    O.chain(([{ between }, state]) =>
      pipe(
        state,
        selectors.state.composeLens(selectors.loadedRanges).get,
        St.some(hasSubRange(time, between)),
        Bool.fold(
          () => [
            actions.fetchEventsOrRecommendationsBetweenAsync.request({
              between,
            }),
          ],
          () => [],
        ),
        arrayToObservable,
      ),
    ),
  )

export const refetchEventsOrRecommendationsFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.refetchEventsOrRecommendations)),
    withLatestFrom(state$),
    O.chain(([, state]) =>
      pipe(
        state,
        selectors.state.composeLens(selectors.loadedRanges).get,
        St.toReadonlyArray(Ord.tuple(time, time)),
        arrayToObservable,
        O.map((between) =>
          actions.fetchEventsOrRecommendationsBetweenAsync.request({
            between,
          }),
        ),
      ),
    ),
  )

export const fetchEventsForSportsStatisticsFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.fetchEventsForSportsStatistics)),
    O.map(() =>
      actions.fetchEventsOrRecommendationsBetweenIfNeeded({
        between: [
          pipe(
            zonedStartOfTodayInCurrentTimeZone,
            IO.map(
              flow(
                (t) => zonedDuration.add(t, mkLocalDuration({ weeks: -4 })),
                zonedTimeToTime.get,
              ),
            ),
          )(),
          pipe(
            zonedStartOfTodayInCurrentTimeZone,
            IO.map(
              flow(
                (t) => zonedDuration.add(t, mkLocalDuration({ weeks: 4 })),
                zonedTimeToTime.get,
              ),
            ),
          )(),
        ],
      }),
    ),
  )

export const fetchEventByIdFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.fetchEventByIdAsync.request)),
    withLatestFrom(O.map(api)(state$)),
    O.chain(
      ([
        {
          payload: { id },
        },
        api,
      ]) =>
        pipe(
          api.events.getEvent(pipe(id, Event.stringAsEventId.reverseGet))("me"),
          TE.fold<AnyAPIError, API.Event, Action>(
            flow(actions.fetchEventByIdAsync.failure, T.of),
            flow(
              (event) =>
                pipe(
                  event,
                  apiToState.event.composePrism(
                    EventOrRecommendation._EventWithId,
                  ).getOption,
                  unsafeFromSome,
                  (event) => actions.fetchEventByIdAsync.success({ event }),
                ),
              T.of,
            ),
          ),
          O.fromTask,
        ),
    ),
  )

export const initNewEventFlow: AnEpic = (action$) => {
  const defaultStart = pipe(
    now,
    IO.map(
      flow(
        timeOfDayOfTime.modify((tod) =>
          mkTimeOfDayWrapped(
            _hour.get(tod),
            ((_minute.get(tod) / 15) | 0) * 15,
            0,
          ),
        ),
        (t) =>
          duration.add(
            t,
            pipe("PT15M", isoStringAsDuration.getOption, unsafeFromSome),
          ),
      ),
    ),
  )

  return pipe(
    action$,
    O.filter(isActionOf(actions.initNewEvent)),
    O.map(
      ({
        payload: {
          between: [start, end],
        },
      }) => {
        const PT1H = pipe("PT1H", isoStringAsDuration.getOption, unsafeFromSome)
        const actualStart = pipe(
          start,
          Opt.map(IO.of),
          Opt.alt(() =>
            pipe(
              end,
              Opt.map((t) => duration.add(t, pipe(PT1H, duration.inverse))),
              Opt.map(IO.of),
            ),
          ),
          Opt.getOrElse(() => defaultStart),
        )

        return actions.startEditingEvent({
          id: Opt.none,
          value: initialEditingEvent(
            actualStart(),
            pipe(
              end,
              Opt.map(IO.of),
              Opt.getOrElse(() =>
                pipe(
                  actualStart,
                  IO.map((t) => duration.add(t, PT1H)),
                ),
              ),
            )(),
          ),
        })
      },
    ),
  )
}

export const startEditingEventByIdFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.startEditingEventById)),
    O.map((a) => a.payload),
    withLatestFrom(state$),
    O.map(([a, state]) => [a, state, api(state)] as const),
    O.chain(([{ eventId }, state, api]) =>
      pipe(
        state,
        selectors.state.composeOptional(_eventsIndex.index(eventId)).getOption,
        Opt.map(
          (value): Event.EventWithId => ({
            id: eventId,
            value,
          }),
        ),
        Opt.fold(
          () =>
            pipe(
              api.events.getEvent(Event.stringAsEventId.reverseGet(eventId))(
                "me",
              ),
              TE.fold<AnyAPIError, API.Event, Action>(
                flow(
                  (e): Action => actions.startEditingEventByIdAsync.failure(e),
                  T.of,
                ),
                flow(
                  apiToState.event.get,
                  EventOrRecommendation.foldEventOrRecommendation(
                    (e) => Opt.some(e),
                    () => Opt.none,
                  ),
                  unsafeFromSome, // TODO
                  (event) =>
                    actions.startEditingEventByIdAsync.success({ event }),
                  T.of,
                ),
              ),
              O.fromTask,
            ),
          (event) =>
            pipe(actions.startEditingEventByIdAsync.success({ event }), O.of),
        ),
      ),
    ),
  )

export const startEditingEventByIdSuccessFlow: AnEpic = (
  action$,
  // state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.startEditingEventByIdAsync.success)),
    O.map((a) => a.payload),
    O.map(
      ({ event: { id, value } }): EditingEvent.EditingEventWithId => ({
        id: Opt.some(id),
        value: EditingEvent.eventAsEditingEvent.get(value),
      }),
    ),
    O.chain((event) => O.of(actions.startEditingEvent(event))),
  )

export const saveEventFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.saveEventAsync.request)),
    O.map((a) => a.payload),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([{ event, changeType }, api]) =>
      pipe(
        api.events.updateEvent(
          prismToGetter(EventOrRecommendation._EventWithId)
            .composeIso(apiToState.event.reverse())
            .get(event),
          changeType,
        )("me"),
        TE.fold<AnyAPIError, API.Event, ReadonlyArray<Action>>(
          flow(actions.saveEventAsync.failure, Arr.of, T.of),
          flow(
            (event) =>
              pipe(
                event,
                apiToState.event.composePrism(
                  EventOrRecommendation._EventWithId,
                ).getOption,
                Opt.fold(
                  () => [],
                  (event) =>
                    pipe(
                      { event, changeType },
                      actions.saveEventAsync.success,
                      Arr.of,
                    ),
                ),
              ),
            T.of,
          ),
        ),
        O.fromTask,
        O.chain(arrayToObservable),
      ),
    ),
  )

export const confirmEventFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.confirmEvent)),
    O.map((a) => a.payload),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([eventId, api]) =>
      pipe(
        api.events.confirmEvent(Event.stringAsEventId.reverseGet(eventId))(
          "me",
        ),
        TE.fold<AnyAPIError, void, ReadonlyArray<Action>>(
          flow(actions.confirmEventAsync.failure, Arr.of, T.of),
          flow(
            () => pipe(actions.confirmEventAsync.success(eventId), Arr.of),
            T.of,
          ),
        ),
        O.fromTask,
        O.chain(arrayToObservable),
      ),
    ),
  )

export const confirmEventSuccessFlow: AnEpic = (action$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.confirmEventAsync.success)),
    O.map(() => actions.calendarUnselect()),
  )

const saveEditingEvent = (
  evt: EditingEvent.EditingEventWithId,
  changeType: EventChangeType,
  api: APIType,
) =>
  pipe(
    evt,
    WithId._id<Opt.Option<Event.EventId>, EditingEvent.EditingEvent>().get,
    Opt.fold(
      () =>
        pipe(
          evt,
          apiToState.editingEventToApiEvent.get,
          api.events.addEvent,
        )("me"),
      () =>
        api.events.updateEvent(
          pipe(evt, apiToState.editingEventToApiEvent.get),
          changeType,
        )("me"),
    ),
    TE.map(Opt.fromNullable),
  )

export const saveEditingEventFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.saveEditingEvent)),
    O.map((a) => a.payload),
    withLatestFrom(state$),
    O.map(([a, state]) => [a, state, api(state)] as const),
    O.chain(([{ changeType }, state, api]) =>
      pipe(
        state,
        selectors.state.composeOptional(selectors.editingEventOpt).getOption,
        Opt.fold(
          () => O.observable.zero<Action>(),
          (evt) =>
            pipe(
              saveEditingEvent(evt, changeType, api),
              TE.fold<
                AnyAPIError,
                Opt.Option<API.Event>,
                ReadonlyArray<Action>
              >(
                flow(actions.saveEditingEventAsync.failure, Arr.of, T.of),
                flow(
                  Opt.chain(
                    apiToState.event.composePrism(
                      EventOrRecommendation._EventWithId,
                    ).getOption,
                  ),
                  Opt.fold(
                    () => [],
                    (event) =>
                      pipe(
                        { event, changeType },
                        actions.saveEditingEventAsync.success,
                        Arr.of,
                      ),
                  ),
                  T.of,
                ),
              ),
              O.fromTask,
              O.chain(arrayToObservable),
            ),
        ),
      ),
    ),
  )

export const deleteEventFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.deleteEvent)),
    O.map((a) => a.payload),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([{ event, changeType }, api]) =>
      pipe(
        api.events.deleteEvent(
          WithId._id<Event.EventId, Event.Event>()
            .composeGetter(prismToGetter(Event.stringAsEventId))
            .get(event),
          changeType,
        )("me"),
        TE.fold<AnyAPIError, void, ReadonlyArray<Action>>(
          flow(actions.deleteEventAsync.failure, Arr.of, T.of),
          flow(
            () =>
              pipe(
                { event, changeType },
                actions.deleteEventAsync.success,
                Arr.of,
              ),
            T.of,
          ),
        ),
        O.fromTask,
        O.chain(arrayToObservable),
      ),
    ),
  )

export const deleteEditingEventFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.deleteEditingEvent)),
    O.map((a) => a.payload),
    withLatestFrom(state$),
    O.map(([a, state]) => [a, state, api(state)] as const),
    O.chain(([{ changeType }, state, api]) =>
      pipe(
        state,
        selectors.state
          .composeOptional(selectors.editingEventOpt)
          .composeLens(WithId._id())
          .composePrism(Prism.some()).getOption,
        Opt.fold(
          () => O.observable.zero<Action>(),
          (evtId) =>
            pipe(
              api.events.deleteEvent(
                pipe(evtId, Event.stringAsEventId.reverseGet),
                changeType,
              )("me"),
              TE.fold<AnyAPIError, void, ReadonlyArray<Action>>(
                flow(actions.deleteEditingEventAsync.failure, Arr.of, T.of),
                flow(
                  () =>
                    pipe(
                      {
                        event: pipe(
                          state,
                          selectors.state
                            .composeLens(selectors.eventsOrRecommendations)
                            .composeOptional(
                              Index.fromAt(
                                EventsOrRecommendations._eventsWithIdAt,
                              ).index(evtId),
                            ).getOption,
                          unsafeFromSome,
                        ),
                        changeType,
                      },
                      actions.deleteEditingEventAsync.success,
                      Arr.of,
                    ),
                  T.of,
                ),
              ),
              O.fromTask,
              O.chain(arrayToObservable),
            ),
        ),
      ),
    ),
  )

export const fetchCatalogForEditingEventFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.fetchCatalogForEditingEventAsync.request)),
    withLatestFrom(state$),
    O.map(([, state]) => [state, api(state)] as const),
    O.chain(([state, api]) =>
      pipe(
        state,
        selectors.state.composeOptional(selectors.editingEventOpt).getOption,
        Opt.fold(
          () => O.observable.zero<Action>(),
          flow(
            (evt) =>
              api.catalog.getCatalog({
                start: WithId._value<
                  Opt.Option<Event.EventId>,
                  EditingEvent.EditingEvent
                >()
                  .composeLens(EditingEvent._start)
                  .get(evt),
                end: WithId._value<
                  Opt.Option<Event.EventId>,
                  EditingEvent.EditingEvent
                >()
                  .composeLens(EditingEvent._end)
                  .get(evt),
                events: Opt.some([apiToState.editingEventToApiEvent.get(evt)]),
              })("me"),
            TE.fold<AnyAPIError, Opt.Option<API.Event>, Action>(
              flow(actions.fetchCatalogForEditingEventAsync.failure, T.of),
              flow(
                Opt.fold(
                  (): ReadonlyArray<ActivityInstanceNonGroup> => Arr.empty,
                  Optional.fromNullableProp<API.Event>()("activities")
                    .composeTraversal(fromTraversable(Arr_.array)())
                    .composeIso(apiToState.activityInstance)
                    // HINT catalog should never return groups
                    .composePrism(_ActivityInstanceNonGroup)
                    .asFold().getAll,
                ),
                (catalog) => ({
                  catalog,
                }),
                actions.fetchCatalogForEditingEventAsync.success,
                T.of,
              ),
            ),
            O.fromTask,
          ),
        ),
      ),
    ),
  )

export const setCatalogSearchVideoFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.setCatalogSearch)),
    O.map((a) => a.payload),
    withLatestFrom(state$),
    O.filter(([_, state]) =>
      pipe(
        state,
        selectors.state
          .composeOptional(selectors.editingEventOpt)
          .composeLens(WithId._value())
          .composeLens(EditingEvent._addActivity)
          .composeLens(AddActivity._step).getOption,
        Opt.fold(
          () => false,
          (step) => step === AddActivity.AddActivityStep.searchVideo,
        ),
      ),
    ),
    debounceTime(500),
    O.chain(([{ value: search }, state]) =>
      pipe(
        { search },
        actions.fetchVideosForEditingEventAsync.request,
        T.of,
        O.fromTask,
      ),
    ),
  )

export const fetchVideosForEditingEventFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.fetchVideosForEditingEventAsync.request)),
    O.map((a) => a.payload),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([{ search }, api]) =>
      pipe(
        search,
        Opt.fold(
          () =>
            pipe(
              { videos: [] },
              actions.fetchVideosForEditingEventAsync.success,
              T.of,
              O.fromTask,
            ),
          (search) =>
            pipe(
              [
                api.workoutVideos.listVideos({ query: search }),
                TE.tryCatch(
                  () =>
                    ytSearch(search, {
                      type: "video",
                      videoCategoryId: "17", // sport
                      key: process.env.REACT_APP_GOOGLE_API_KEY,
                    }),
                  (e: unknown): AnyAPIError => e as any,
                ),
              ] as const,
              // HINT: still show youtube results when video service API fails
              Tuple.bimap(
                TE.fold(
                  (
                    err,
                  ): TE.TaskEither<AnyAPIError, ReadonlyArray<WorkoutVideo>> =>
                    pipe(err, TE.left),
                  (yt) =>
                    pipe(
                      yt.results,
                      Arr.map(apiToState.youtubeWorkoutVideo.get),
                      TE.right,
                    ),
                ),
                TE.fold(
                  (
                    err,
                  ): TE.TaskEither<AnyAPIError, ReadonlyArray<WorkoutVideo>> =>
                    pipe(
                      Arr.empty as ReadonlyArray<WorkoutVideo>,
                      inspect((a) =>
                        console.error(
                          "Getting videos from wotkout video API failed",
                          err,
                        ),
                      ),
                      TE.right,
                    ),
                  (fp) =>
                    pipe(fp, protoToState.workoutVideoResponse.get, TE.right),
                ),
              ),
              bisequencePair(TE.ApplyPar),
              TE.map(([fp, yt]) => [...fp, ...yt]),
              TE.fold<AnyAPIError, ReadonlyArray<WorkoutVideo>, Action>(
                flow(actions.fetchVideosForEditingEventAsync.failure, T.of),
                flow(
                  (videos) => ({ videos }),
                  actions.fetchVideosForEditingEventAsync.success,
                  T.of,
                ),
              ),
              O.fromTask,
            ),
        ),
      ),
    ),
  )

export const fetchRecommendationsForCalendarFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.fetchRecommendationsForCalendar)),
    O.map((a) => a.payload),
    withLatestFrom(state$),
    O.map(([a, state]) => [a, state, api(state)] as const),
    O.chain(([rec, , api]) =>
      pipe(rec, ({ between, scheduledMuscles }) =>
        pipe(
          api.activities.getRecommendations({
            start: Opt.some(between[0]),
            end: Opt.some(between[1]),
            scheduledMuscles: pipe(
              scheduledMuscles,
              Arr.map(([id, regenerationSlope]) => ({
                id: stringAsMuscleId.reverseGet(id),
                regenerationSlope: prismPositive.reverseGet(regenerationSlope),
              })),
              Opt.some,
            ),
          })("me"),
          TE.fold<AnyAPIError, ReadonlyArray<API.Event>, Action>(
            flow(actions.fetchRecommendationsForCalendarAsync.failure, T.of),
            flow(
              arrTrav<API.Event>().composeGetter(apiToState.recommendationEvent)
                .getAll,
              Arr.map(
                (evt: Event.RecommendationEvent): Event.Event =>
                  Event._recurrence.set(rec.recurrence)(evt as Event.Event),
              ),
              (recommendations) => ({
                recommendations,
              }),
              actions.fetchRecommendationsForCalendarAsync.success,
              T.of,
            ),
          ),
          O.fromTask,
        ),
      ),
    ),
  )

export const fetchRecommendationsForEditingEventFlow: AnEpic = (
  action$,
  state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.fetchRecommendationsForEditingEvent)),
    O.map((a) => a.payload),
    withLatestFrom(state$),
    O.map(([a, state]) => [a, state, api(state)] as const),
    O.chain(([, state, api]) =>
      pipe(
        state,
        selectors.state
          .composeOptional(selectors.editingEventOpt)
          .composeLens(WithId._value())
          .composeLens(EditingEvent._between).getOption,
        Opt.fold(
          () => O.observable.zero<Action>(),
          ([start, end]) =>
            pipe(
              api.activities.getRecommendations({
                start: Opt.some(start),
                end: Opt.some(end),
                scheduledMuscles: Opt.none,
              })("me"),
              TE.fold<AnyAPIError, ReadonlyArray<API.Event>, Action>(
                flow(
                  actions.fetchRecommendationsForEditingEventAsync.failure,
                  T.of,
                ),
                flow(
                  arrTrav<API.Event>().composeGetter(
                    apiToState.recommendationEvent,
                  ).getAll,
                  (recommendations) => ({
                    recommendations,
                  }),
                  actions.fetchRecommendationsForEditingEventAsync.success,
                  T.of,
                ),
              ),
              O.fromTask,
            ),
        ),
      ),
    ),
  )

export const calendarSelectFlow: AnEpic = (action$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.calendarSelect)),
    O.map((a) => a.payload),
    O.filterMap(CalendarSelection._CalendarSelectionRecommendation.getOption),
    O.map(actions.fetchRecommendationsForCalendar),
  )

export const fetchCalendarSuccessFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.fetchRecommendationsForCalendarAsync.success)),
    O.map((a) => a.payload),
    withLatestFrom(state$),
    O.filterMap(([payload, state]) =>
      pipe(
        state,
        selectors.state.composeLens(selectors.calendarSelection).get,
        Opt.map(() => payload),
      ),
    ),
    O.map(({ recommendations }) =>
      actions.calendarSelect(
        CalendarSelection._CalendarSelectionRecommendationWithActivities.reverseGet(
          recommendations[0],
        ),
      ),
    ),
  )

export const refetchEventsOrRecommendationsOnEventChangeFlow: AnEpic = (
  action$,
  state$,
) =>
  pipe(
    action$,
    O.filter(
      (act): act is Action & { payload: { changeType: EventChangeType } } =>
        isActionOf(actions.saveEventAsync.success)(act) ||
        isActionOf(actions.saveEditingEventAsync.success)(act) ||
        isActionOf(actions.deleteEventAsync.success)(act) ||
        isActionOf(actions.deleteEditingEventAsync.success)(act),
    ),
    O.map((a) => a.payload.changeType),
    O.filter((ct) => ct !== EventChangeType.Single),
    O.map(() => actions.refetchEventsOrRecommendations()),
  )

export const refetchEventsOrRecommendationsOnOtherChanges: AnEpic = (
  action$,
  state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(UserData.setUserDataAsync.success)),
    O.map(() => actions.refetchEventsOrRecommendations()),
  )

export const cancelEditingEventFlow: AnEpic = (
  action$,
  // state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.cancelEditingEvent)),
    O.map((a) => a.payload),
    O.map(({ navigate }) =>
      pipe(
        navigate,
        Bool.fold(
          () => [],
          () => [pushHistory("/calendar")],
        ),
      ),
    ),
    O.chain(arrayToObservable),
  )

export const locationChangeFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isOfType(LOCATION_CHANGE)),
    withLatestFrom(state$),
    O.chain(([, state]) =>
      pipe(
        state,
        selectors.state.composeLens(selectors.calendarSelection).get,
        Opt.fold(
          () => [],
          () => [actions.calendarUnselect()],
        ),
        arrayToObservable,
      ),
    ),
  )

const epic: AnEpic = combineEpics(
  setTokenFlow,
  setAccountReadyFlow,
  fetchEventsOrRecommendationsBetweenFlow,
  fetchEventsOrRecommendationsBetweenIfNeededFlow,
  refetchEventsOrRecommendationsFlow,
  fetchEventsForSportsStatisticsFlow,
  fetchEventByIdFlow,
  initNewEventFlow,
  startEditingEventByIdFlow,
  startEditingEventByIdSuccessFlow,
  saveEventFlow,
  confirmEventFlow,
  saveEditingEventFlow,
  deleteEventFlow,
  deleteEditingEventFlow,
  cancelEditingEventFlow,
  fetchCatalogForEditingEventFlow,
  setCatalogSearchVideoFlow,
  fetchVideosForEditingEventFlow,
  fetchRecommendationsForCalendarFlow,
  fetchRecommendationsForEditingEventFlow,
  calendarSelectFlow,
  fetchCalendarSuccessFlow,
  refetchEventsOrRecommendationsOnEventChangeFlow,
  refetchEventsOrRecommendationsOnOtherChanges,
  locationChangeFlow,
)
export default epic
