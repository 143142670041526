/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the Job interface.
 */
export function instanceOfJob(value) {
    if (!("id" in value) || value.id === undefined)
        return false;
    if (!("isCustom" in value) || value.isCustom === undefined)
        return false;
    return true;
}
export function JobFromJSON(json) {
    return JobFromJSONTyped(json, false);
}
export function JobFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        id: json.id,
        name: json.name == null ? undefined : json.name,
        isCustom: json.isCustom,
        physicalActivity: json.physicalActivity == null ? undefined : json.physicalActivity,
        mentalActivity: json.mentalActivity == null ? undefined : json.mentalActivity,
    };
}
export function JobToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        id: value.id,
        name: value.name,
        isCustom: value.isCustom,
        physicalActivity: value.physicalActivity,
        mentalActivity: value.mentalActivity,
    };
}
