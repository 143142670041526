/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventFromJSON, EventRequestToJSON, } from "../models/index";
import * as runtime from "../runtime";
/**
 *
 */
export class CatalogApi extends runtime.BaseAPI {
    /**
     */
    async calendarApiUsersUserIdCalendarCatalogGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling calendarApiUsersUserIdCalendarCatalogGet().');
        }
        const queryParameters = {};
        if (requestParameters.start != null) {
            queryParameters.start = requestParameters.start.toISOString();
        }
        if (requestParameters.end != null) {
            queryParameters.end = requestParameters.end.toISOString();
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calendar/api/users/{userId}/calendar/catalog`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => EventFromJSON(jsonValue));
    }
    /**
     */
    async calendarApiUsersUserIdCalendarCatalogGet(requestParameters, initOverrides) {
        const response = await this.calendarApiUsersUserIdCalendarCatalogGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Handles POST requests for Calendar.Controllers.Calendars.CatalogController.
     */
    async calendarApiUsersUserIdCalendarCatalogPostRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling calendarApiUsersUserIdCalendarCatalogPost().');
        }
        const queryParameters = {};
        if (requestParameters.start != null) {
            queryParameters.start = requestParameters.start.toISOString();
        }
        if (requestParameters.end != null) {
            queryParameters.end = requestParameters.end.toISOString();
        }
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calendar/api/users/{userId}/calendar/catalog`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: EventRequestToJSON(requestParameters.eventRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => EventFromJSON(jsonValue));
    }
    /**
     * Handles POST requests for Calendar.Controllers.Calendars.CatalogController.
     */
    async calendarApiUsersUserIdCalendarCatalogPost(requestParameters, initOverrides) {
        const response = await this.calendarApiUsersUserIdCalendarCatalogPostRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }
}
