/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UnitHeightFromJSON, UnitHeightToJSON, } from "./UnitHeight";
/**
 * Check if a given object implements the Option1UnitHeight interface.
 */
export function instanceOfOption1UnitHeight(value) {
    if (!("value" in value) || value.value === undefined)
        return false;
    if (!("name" in value) || value.name === undefined)
        return false;
    if (!("description" in value) || value.description === undefined)
        return false;
    return true;
}
export function Option1UnitHeightFromJSON(json) {
    return Option1UnitHeightFromJSONTyped(json, false);
}
export function Option1UnitHeightFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        value: UnitHeightFromJSON(json.value),
        name: json.name,
        description: json.description,
    };
}
export function Option1UnitHeightToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        value: UnitHeightToJSON(value.value),
        name: value.name,
        description: value.description,
    };
}
