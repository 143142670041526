/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserAccountFromJSON, UserAccountToJSON, } from "../models/index";
import * as runtime from "../runtime";
/**
 *
 */
export class AccountDataApi extends runtime.BaseAPI {
    /**
     * Deletes the user account.
     */
    async userManagementApiUsersUserIdAccountDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling userManagementApiUsersUserIdAccountDelete().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/account`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Deletes the user account.
     */
    async userManagementApiUsersUserIdAccountDelete(requestParameters, initOverrides) {
        await this.userManagementApiUsersUserIdAccountDeleteRaw(requestParameters, initOverrides);
    }
    /**
     */
    async userManagementApiUsersUserIdAccountGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling userManagementApiUsersUserIdAccountGet().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/account`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UserAccountFromJSON(jsonValue));
    }
    /**
     */
    async userManagementApiUsersUserIdAccountGet(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdAccountGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userManagementApiUsersUserIdAccountPutRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling userManagementApiUsersUserIdAccountPut().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/account`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: UserAccountToJSON(requestParameters.userAccount),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UserAccountFromJSON(jsonValue));
    }
    /**
     */
    async userManagementApiUsersUserIdAccountPut(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdAccountPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
