/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BodyDataFromJSON, BodyDataToJSON, } from "./BodyData";
import { SexFromJSON, SexToJSON } from "./Sex";
import { UserHabitsFromJSON, UserHabitsToJSON, } from "./UserHabits";
import { UserSleepFromJSON, UserSleepToJSON, } from "./UserSleep";
import { UserWorkFromJSON, UserWorkToJSON, } from "./UserWork";
/**
 * Check if a given object implements the ApiUserData interface.
 */
export function instanceOfApiUserData(value) {
    return true;
}
export function ApiUserDataFromJSON(json) {
    return ApiUserDataFromJSONTyped(json, false);
}
export function ApiUserDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        sex: json.sex == null ? undefined : SexFromJSON(json.sex),
        birthDate: json.birthDate == null ? undefined : new Date(json.birthDate),
        bodyData: json.bodyData == null ? undefined : BodyDataFromJSON(json.bodyData),
        work: json.work == null ? undefined : UserWorkFromJSON(json.work),
        sleep: json.sleep == null ? undefined : UserSleepFromJSON(json.sleep),
        habits: json.habits == null ? undefined : UserHabitsFromJSON(json.habits),
    };
}
export function ApiUserDataToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        sex: SexToJSON(value.sex),
        birthDate: value.birthDate == null
            ? undefined
            : value.birthDate.toISOString().substring(0, 10),
        bodyData: BodyDataToJSON(value.bodyData),
        work: UserWorkToJSON(value.work),
        sleep: UserSleepToJSON(value.sleep),
        habits: UserHabitsToJSON(value.habits),
    };
}
