// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file gateway/v1/workout_videos.proto (package gateway.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { WorkoutVideo } from "../../video/v1/workout_video_pb.js";
import { Activity } from "../../activity/v1/activity_pb.js";

/**
 * @generated from message gateway.v1.ListVideosRequest
 */
export class ListVideosRequest extends Message<ListVideosRequest> {
  /**
   * search string to filter by
   *
   * @generated from field: string query = 1;
   */
  query = "";

  constructor(data?: PartialMessage<ListVideosRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gateway.v1.ListVideosRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListVideosRequest {
    return new ListVideosRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListVideosRequest {
    return new ListVideosRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListVideosRequest {
    return new ListVideosRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListVideosRequest | PlainMessage<ListVideosRequest> | undefined, b: ListVideosRequest | PlainMessage<ListVideosRequest> | undefined): boolean {
    return proto3.util.equals(ListVideosRequest, a, b);
  }
}

/**
 * @generated from message gateway.v1.ListVideosResponse
 */
export class ListVideosResponse extends Message<ListVideosResponse> {
  /**
   * @generated from field: repeated video.v1.WorkoutVideo videos = 1;
   */
  videos: WorkoutVideo[] = [];

  /**
   * @generated from field: repeated activity.v1.Activity activities = 2;
   */
  activities: Activity[] = [];

  constructor(data?: PartialMessage<ListVideosResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gateway.v1.ListVideosResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "videos", kind: "message", T: WorkoutVideo, repeated: true },
    { no: 2, name: "activities", kind: "message", T: Activity, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListVideosResponse {
    return new ListVideosResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListVideosResponse {
    return new ListVideosResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListVideosResponse {
    return new ListVideosResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListVideosResponse | PlainMessage<ListVideosResponse> | undefined, b: ListVideosResponse | PlainMessage<ListVideosResponse> | undefined): boolean {
    return proto3.util.equals(ListVideosResponse, a, b);
  }
}

