/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiSettingsFromJSON, ApiSettingsToJSON, } from "../models/index";
import * as runtime from "../runtime";
/**
 *
 */
export class SettingsApi extends runtime.BaseAPI {
    /**
     */
    async googleFitImportApiUsersUserIdSettingsDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling googleFitImportApiUsersUserIdSettingsDelete().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/googleFitImport/api/users/{userId}/settings`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async googleFitImportApiUsersUserIdSettingsDelete(requestParameters, initOverrides) {
        await this.googleFitImportApiUsersUserIdSettingsDeleteRaw(requestParameters, initOverrides);
    }
    /**
     */
    async googleFitImportApiUsersUserIdSettingsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling googleFitImportApiUsersUserIdSettingsGet().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/googleFitImport/api/users/{userId}/settings`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSettingsFromJSON(jsonValue));
    }
    /**
     */
    async googleFitImportApiUsersUserIdSettingsGet(requestParameters, initOverrides) {
        const response = await this.googleFitImportApiUsersUserIdSettingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async googleFitImportApiUsersUserIdSettingsPutRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling googleFitImportApiUsersUserIdSettingsPut().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/googleFitImport/api/users/{userId}/settings`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: ApiSettingsToJSON(requestParameters.apiSettings),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async googleFitImportApiUsersUserIdSettingsPut(requestParameters, initOverrides) {
        await this.googleFitImportApiUsersUserIdSettingsPutRaw(requestParameters, initOverrides);
    }
}
