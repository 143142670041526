/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityInstanceFromJSON, ActivityInstanceToJSON, } from "./ActivityInstance";
import { EventSourceFromJSON, EventSourceToJSON, } from "./EventSource";
import { EventTypeFromJSON, EventTypeToJSON, } from "./EventType";
import { RecurringPatternFromJSON, RecurringPatternToJSON, } from "./RecurringPattern";
import { ScheduledMuscleFromJSON, ScheduledMuscleToJSON, } from "./ScheduledMuscle";
import { UnitsOverrideFromJSON, UnitsOverrideToJSON, } from "./UnitsOverride";
import { WorkoutVideoFromJSON, WorkoutVideoToJSON, } from "./WorkoutVideo";
/**
 * Check if a given object implements the Event interface.
 */
export function instanceOfEvent(value) {
    if (!("type" in value) || value.type === undefined)
        return false;
    if (!("alignment" in value) || value.alignment === undefined)
        return false;
    if (!("start" in value) || value.start === undefined)
        return false;
    if (!("end" in value) || value.end === undefined)
        return false;
    if (!("isRecommendation" in value) || value.isRecommendation === undefined)
        return false;
    if (!("isBestMatch" in value) || value.isBestMatch === undefined)
        return false;
    if (!("recurringPattern" in value) || value.recurringPattern === undefined)
        return false;
    if (!("recurringInterval" in value) || value.recurringInterval === undefined)
        return false;
    return true;
}
export function EventFromJSON(json) {
    return EventFromJSONTyped(json, false);
}
export function EventFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        id: json.id == null ? undefined : json.id,
        source: json.source == null ? undefined : EventSourceFromJSON(json.source),
        title: json.title == null ? undefined : json.title,
        type: EventTypeFromJSON(json.type),
        video: json.video == null ? undefined : WorkoutVideoFromJSON(json.video),
        alignment: json.alignment,
        start: new Date(json.start),
        end: new Date(json.end),
        confirmed: json.confirmed == null ? undefined : new Date(json.confirmed),
        isRecommendation: json.isRecommendation,
        isBestMatch: json.isBestMatch,
        recurringPattern: RecurringPatternFromJSON(json.recurringPattern),
        recurringInterval: json.recurringInterval,
        daysOfWeek: json.daysOfWeek == null ? undefined : json.daysOfWeek,
        dayOfMonth: json.dayOfMonth == null ? undefined : json.dayOfMonth,
        weekOfMonth: json.weekOfMonth == null ? undefined : json.weekOfMonth,
        dayofweek: json.dayofweek == null ? undefined : json.dayofweek,
        recurrenceStart: json.recurrenceStart == null ? undefined : new Date(json.recurrenceStart),
        recurrenceEnd: json.recurrenceEnd == null ? undefined : new Date(json.recurrenceEnd),
        activities: json.activities == null
            ? undefined
            : json.activities.map(ActivityInstanceFromJSON),
        scheduledMuscles: json.scheduledMuscles == null
            ? undefined
            : json.scheduledMuscles.map(ScheduledMuscleFromJSON),
        units: json.units == null ? undefined : UnitsOverrideFromJSON(json.units),
    };
}
export function EventToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        id: value.id,
        source: EventSourceToJSON(value.source),
        title: value.title,
        type: EventTypeToJSON(value.type),
        video: WorkoutVideoToJSON(value.video),
        alignment: value.alignment,
        start: value.start.toISOString(),
        end: value.end.toISOString(),
        confirmed: value.confirmed == null
            ? undefined
            : value.confirmed.toISOString(),
        isRecommendation: value.isRecommendation,
        isBestMatch: value.isBestMatch,
        recurringPattern: RecurringPatternToJSON(value.recurringPattern),
        recurringInterval: value.recurringInterval,
        daysOfWeek: value.daysOfWeek,
        dayOfMonth: value.dayOfMonth,
        weekOfMonth: value.weekOfMonth,
        dayofweek: value.dayofweek,
        recurrenceStart: value.recurrenceStart == null
            ? undefined
            : value.recurrenceStart.toISOString(),
        recurrenceEnd: value.recurrenceEnd == null
            ? undefined
            : value.recurrenceEnd.toISOString(),
        activities: value.activities == null
            ? undefined
            : value.activities.map((a) => ActivityInstanceToJSON(a)),
        scheduledMuscles: value.scheduledMuscles == null
            ? undefined
            : value.scheduledMuscles.map(ScheduledMuscleToJSON),
        units: UnitsOverrideToJSON(value.units),
    };
}
