import * as E from "fp-ts/es6/Either"
import { left, right } from "fp-ts/es6/Either"
import * as Func from "fp-ts/es6/function"
import { flow, Lazy, pipe } from "fp-ts/es6/function"
import { Functor1 } from "fp-ts/es6/Functor"
import { Kind, URIS } from "fp-ts/es6/HKT"
import * as Opt from "fp-ts/es6/Option"
import * as R from "fp-ts/es6/Reader"
import * as RT from "fp-ts/es6/ReaderT"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as T from "fp-ts/es6/Task"
import * as TE from "fp-ts/es6/TaskEither"
import { dateAsTime, Time } from "time-ts/es6"

import * as combined from "@fitnesspilot/api-combined"
import { Event, ScheduledMuscle } from "@fitnesspilot/api-combined"
import * as proto from "@fitnesspilot/proto"

import * as grpc from "./grpc"

export {
  Activity,
  ActivityInstanceExercise,
  ActivityInstanceGroup,
  ActivityInstanceJob,
  ActivityInstanceMuscles,
  ActivityInstanceSleep,
  ActivityTag,
  ActivityType,
  ApiUserData,
  EventChangeType,
  EventResponse,
  EventType,
  ModelSet as ExerciseSet,
  RecurringPattern,
  UserAccount,
} from "@fitnesspilot/api-combined"
export { Event }

export enum ApiErrorCode {
  ConcurrencyError = "DBConcurrencyError",
  InviteCodeInvalid = "USRInviteCodeInvalid",
  ReferralCodeInvalid = "USRReferralCodeInvalid",
  DuplicateUserId = "USRDuplicateUserId",
  DuplicateUserName = "USRDuplicateUserName",
  DuplicateEmail = "USRDuplicateEmail",
  UserNotFound = "USRUserNotFound",
  UserEmailNotFound = "USRUserEmailNotFound",
  PasswordIncorrect = "USRPasswordIncorrect",
  AlreadySignedUp = "USRAlreadySignedUp",
  AccessDenied = "USRAccessDenied",
}

type ProblemDetails = {
  title?: string
  detail?: string
  status?: number
  errorCode?: ApiErrorCode
  stackTraces?: Array<string>
}
const isErrResponse = (v: any): v is ProblemDetails =>
  typeof v === "object" &&
  (v.title == null || typeof v.title === "string") &&
  (v.detail == null || typeof v.detail === "string") &&
  (v.status == null || typeof v.status === "number") &&
  (v.errorCode == null || ["string", "number"].includes(typeof v.errorCode)) &&
  (v.stackTraces == null || Array.isArray(v.stackTraces))
const asErrResponse = Opt.fromPredicate(isErrResponse)
export class APIError extends Error {
  public readonly errorCode?: string | number
  public readonly stackTraces: Array<string> = []
  public readonly statusCode: number

  constructor(
    { title, detail, errorCode, stackTraces }: ProblemDetails,
    { status: statusCode }: Response,
  ) {
    super(detail ?? title)
    this.errorCode = errorCode
    this.stackTraces = stackTraces ?? []
    this.statusCode = statusCode
    Error?.captureStackTrace?.(this, APIError)
  }
}
export const isAPIError = (e: Error): e is APIError => e instanceof APIError

type UserProblemDetails = ProblemDetails & {
  title: string
}
const isUserErrResponse = (v: any): v is UserProblemDetails =>
  typeof v === "object" && v.title
const asUserErrResponse = Opt.fromPredicate(isUserErrResponse)
export class UserAPIError extends APIError {
  public readonly userMessage: string

  constructor(
    { title, detail, errorCode, stackTraces }: UserProblemDetails,
    res: Response,
  ) {
    super({ title, detail, errorCode, stackTraces }, res)
    this.userMessage = title
    Error?.captureStackTrace?.(this, UserAPIError)
  }
}
export const isUserAPIError = (e: Error): e is UserAPIError =>
  e instanceof UserAPIError

const asString = Opt.fromPredicate((v): v is string => typeof v === "string")

const uncozipL =
  <F extends URIS>(F: Functor1<F>) =>
  <e, a>(v: E.Either<Kind<F, e>, Kind<F, a>>) =>
    E.fold<Kind<F, e>, Kind<F, a>, Kind<F, E.Either<e, a>>>(
      (e) => F.map<e, E.Either<e, a>>(e, E.left),
      (a) => F.map<a, E.Either<e, a>>(a, E.right),
    )(v)
const uncozipLTask = uncozipL(T.task)

export type AnyAPIError = UserAPIError | APIError | Error

const wrap = <a>(f: Lazy<Promise<a>>) =>
  flow(
    (f: Lazy<Promise<a>>) =>
      TE.tryCatch(f, (v: unknown) =>
        v instanceof Response ? right(v) : left(v as Error),
      ),
    TE.mapLeft(
      E.fold<Error, Response, T.Task<AnyAPIError>>(T.of, (e) => {
        const contentType = e.headers.get("content-type")
        if (contentType && /application\/(?:json|.*\+json)/.test(contentType)) {
          return flow(
            <a>(f: Lazy<Promise<a>>) =>
              TE.tryCatch(f, Func.identity as (v: unknown) => Error),
            TE.map<any, AnyAPIError>((v) =>
              flow(
                Arr.map((f: (v2: typeof v) => Opt.Option<AnyAPIError>) => f(v)),
                Arr.reduceRight(Opt.option.zero(), (a, b) =>
                  Opt.option.alt(a, () => b),
                ),
                Opt.fold<AnyAPIError, AnyAPIError>(
                  () => new Error("Impossible condition reached"),
                  (e) => e,
                ),
              )([
                flow(
                  asUserErrResponse,
                  Opt.map((v) => new UserAPIError(v, e)),
                ),
                flow(
                  asErrResponse,
                  Opt.map((v) => new APIError(v, e)),
                ),
                flow(
                  asString,
                  Opt.map((v) => new APIError({ title: v }, e)),
                ),
                (v) => {
                  console.error("[api] invalid err response %o", v)
                  return Opt.some(new APIError({ title: "Unknown error" }, e))
                },
              ]),
            ),
            TE.fold(T.of, T.of),
          )(() => e.json())
        } else {
          return flow(
            (f: Lazy<Promise<string>>) =>
              TE.tryCatch(f, Func.identity as (v: unknown) => Error),
            TE.map((v) => new APIError({ title: v }, e)),
            TE.fold(T.of, T.of),
          )(() => e.text())
        }
      }),
    ),
    TE.map(T.of),
    T.chain(uncozipLTask) as (
      v: TE.TaskEither<T.Task<AnyAPIError>, T.Task<a>>,
    ) => TE.TaskEither<AnyAPIError, a>,
  )(f)

const wrapFunc =
  <a extends Array<any>, r>(f: (...as: a) => Promise<r>) =>
  (...as: a) =>
    wrap(() => f(...as))

type UserId = string
const wrapFuncUserId = <a extends Array<any>, r>(
  f: (a1: { userId: string }) => Promise<r>,
): RT.ReaderT2<TE.URI, UserId, AnyAPIError, r> =>
  R.asks((userId: UserId) => wrap(() => f({ userId })))
const wrapFuncWithUserId =
  <a1 extends { userId: string }, a extends Array<any>, r>(
    f: (a1: a1, ...as: a) => Promise<r>,
  ) =>
  (
    a1: Omit<a1, "userId">,
    ...as: a
  ): RT.ReaderT2<TE.URI, UserId, AnyAPIError, r> =>
    R.asks((userId: UserId) => wrap(() => f({ ...a1, userId } as a1, ...as)))

type GetEventsBetween = <includeTimeslots extends boolean = true>(args: {
  start: Opt.Option<Time>
  end: Opt.Option<Time>
  includeTimeslots: includeTimeslots
}) => RT.ReaderT2<
  TE.URI,
  UserId,
  AnyAPIError,
  includeTimeslots extends true
    ? combined.EventResponse
    : Omit<combined.EventResponse, "timeslots">
>

const api = (baseUrl: string, getToken: () => string | Promise<string>) => {
  // HTTP APIs
  const httpConfig = new combined.Configuration({
    basePath: baseUrl,
    accessToken: getToken,
  })
  const photos = new combined.PhotosApi(httpConfig)
  const events = new combined.EventsApi(httpConfig)
  const catalog = new combined.CatalogApi(httpConfig)
  const userRegister = new combined.UserRegisterApi(httpConfig)
  const wizard = new combined.ApplyWizardApi(httpConfig)
  const accounts = new combined.AccountDataApi(httpConfig)
  const subscriptions = new combined.SubscriptionsApi(httpConfig)
  const users = new combined.DataApi(httpConfig)
  const summaries = new combined.SummaryApi(httpConfig)
  const units = new combined.UnitsApi(httpConfig)
  const today = new combined.TodayApi(httpConfig)
  const activities = new combined.ActivitiesApi(httpConfig)
  const preferences = new combined.PreferencesApi(httpConfig)
  const sports = new combined.SportsApi(httpConfig)
  const recommendations = new combined.RecommendationsApi(httpConfig)
  const muscleSettings = new combined.MuscleSettingsApi(httpConfig)
  const jobs = new combined.JobsApi(httpConfig)
  const googleApi = new combined.GoogleApiApi(httpConfig)
  const googleFit = new combined.SettingsApi(httpConfig)
  const referrals = new combined.ReferralsApi(httpConfig)
  const invites = new combined.InvitesApi(httpConfig)
  const help = new combined.HelpApi(httpConfig)

  // gRPC APIs
  const grpcTransports = {
    video: grpc.createTransport(`${baseUrl}/video/grpc`, getToken),
  }
  const workoutVideos = grpc.createClient(
    proto.WorkoutVideosGrpc,
    grpcTransports.video,
  )

  return {
    photos: {
      getPhotos: wrapFuncUserId((...as) =>
        photos.userManagementApiUsersUserIdPhotosGet(...as),
      ),
      getPhoto: pipe(
        wrapFuncWithUserId(
          (
            ...as: [
              combined.UserManagementApiUsersUserIdPhotosPhotoIdGetRequest,
            ]
          ) => photos.userManagementApiUsersUserIdPhotosPhotoIdGet(...as),
        ),
        R.local((photoId: string) => ({ photoId })),
      ),
      addPhoto: wrapFuncWithUserId(
        (...as: [combined.UserManagementApiUsersUserIdPhotosPostRequest]) =>
          photos.userManagementApiUsersUserIdPhotosPost(...as),
      ),
      updatePhoto: (
        photoId: string,
        operation: combined.JsonPatchDocument1PhotoDownload,
      ) =>
        wrapFuncWithUserId(
          (
            ...as: [
              combined.UserManagementApiUsersUserIdPhotosPhotoIdPatchRequest,
            ]
          ) => photos.userManagementApiUsersUserIdPhotosPhotoIdPatch(...as),
        )({
          photoId,
          jsonPatchDocument1PhotoDownload: operation,
        }),
      setProfilePhoto: pipe(
        wrapFuncWithUserId(
          (
            ...as: [
              combined.UserManagementApiUsersUserIdPhotosProfilePhotoPutRequest,
            ]
          ) => photos.userManagementApiUsersUserIdPhotosProfilePhotoPut(...as),
        ),
        R.local((photoId: string) => ({ body: photoId })),
      ),
      getProfilePhoto: wrapFuncUserId((...as) =>
        photos.userManagementApiUsersUserIdPhotosProfilePhotoGet(...as),
      ),
      removeProfilePhoto: wrapFuncUserId((...as) =>
        photos.userManagementApiUsersUserIdPhotosProfilePhotoDelete(...as),
      ),
    },
    events: {
      getEventsBetween: pipe(
        wrapFuncWithUserId(
          (...as: [combined.CalendarApiUsersUserIdCalendarEventsGetRequest]) =>
            events.calendarApiUsersUserIdCalendarEventsGet(...as),
        ),
        R.local(
          ({
            start,
            end,
            includeTimeslots,
          }: {
            start: Opt.Option<Time>
            end: Opt.Option<Time>
            includeTimeslots: boolean
          }) => ({
            start: pipe(start, Opt.map(dateAsTime.reverseGet), Opt.toUndefined),
            end: pipe(end, Opt.map(dateAsTime.reverseGet), Opt.toUndefined),
            includeTimeslots,
          }),
        ),
      ) as GetEventsBetween,
      addEvent: pipe(
        wrapFuncWithUserId(
          (...as: [combined.CalendarApiUsersUserIdCalendarEventsPostRequest]) =>
            events.calendarApiUsersUserIdCalendarEventsPost(...as),
        ),
        R.local((event: combined.Event) => ({ event })),
      ),
      getEvent: pipe(
        wrapFuncWithUserId(
          (
            ...as: [combined.CalendarApiUsersUserIdCalendarEventsIdGetRequest]
          ) => events.calendarApiUsersUserIdCalendarEventsIdGet(...as),
        ),
        R.local((id: string) => ({ id })),
      ),
      updateEvent: (event: combined.Event, change: combined.EventChangeType) =>
        wrapFuncWithUserId(
          (
            ...as: [combined.CalendarApiUsersUserIdCalendarEventsIdPutRequest]
          ) => events.calendarApiUsersUserIdCalendarEventsIdPut(...as),
        )({
          id: event.id!,
          change,
          event,
        }),
      deleteEvent: (id: string, change: combined.EventChangeType) =>
        wrapFuncWithUserId(
          (
            ...as: [
              combined.CalendarApiUsersUserIdCalendarEventsIdDeleteRequest,
            ]
          ) => events.calendarApiUsersUserIdCalendarEventsIdDelete(...as),
        )({
          id,
          change,
        }),
      confirmEvent: (id: string) =>
        wrapFuncWithUserId(
          (
            ...as: [
              combined.CalendarApiUsersUserIdCalendarEventsIdConfirmPutRequest,
            ]
          ) => events.calendarApiUsersUserIdCalendarEventsIdConfirmPut(...as),
        )({
          id,
        }),
    },
    catalog: {
      getCatalog: ({
        start,
        end,
        events,
      }: {
        start: Time
        end: Time
        events: Opt.Option<NonEmpty.ReadonlyNonEmptyArray<Event>>
      }) =>
        R.asks((userId: UserId) =>
          wrap(() =>
            pipe(
              events,
              Opt.fold(
                () =>
                  catalog.calendarApiUsersUserIdCalendarCatalogGet({
                    userId,
                    start: dateAsTime.reverseGet(start),
                    end: dateAsTime.reverseGet(end),
                  }),
                (events) =>
                  catalog.calendarApiUsersUserIdCalendarCatalogPost({
                    userId,
                    start: dateAsTime.reverseGet(start),
                    end: dateAsTime.reverseGet(end),
                    eventRequest: { events: Arr.toArray(events) },
                  }),
              ),
              (a) => a.then<Opt.Option<Event>>(Opt.fromNullable),
            ),
          ),
        ),
    },
    users: {
      signup: wrapFunc(
        (...as: [combined.UserManagementApiUsersRegisterPostRequest]) =>
          userRegister.userManagementApiUsersRegisterPost(...as),
      ),
      setup: pipe(
        wrapFuncWithUserId(
          (...as: [combined.UserManagementApiUsersUserIdWizardPostRequest]) =>
            wizard.userManagementApiUsersUserIdWizardPost(...as),
        ),
        R.local((wizardData: combined.WizardData) => ({
          wizardData: wizardData,
        })),
      ),
      getAccount: wrapFuncUserId((...as) =>
        accounts.userManagementApiUsersUserIdAccountGet(...as),
      ),
      deleteAccount: wrapFuncUserId((...as) =>
        accounts.userManagementApiUsersUserIdAccountDelete(...as),
      ),
      updateAccount: pipe(
        wrapFuncWithUserId(
          (...as: [combined.UserManagementApiUsersUserIdAccountPutRequest]) =>
            accounts.userManagementApiUsersUserIdAccountPut(...as),
        ),
        R.local((account: combined.UserAccount) => ({ userAccount: account })),
      ),
      getUser: wrapFuncUserId((...as) =>
        users.userManagementApiUsersUserIdAboutmeGet(...as),
      ),
      updateUser: pipe(
        wrapFuncWithUserId(
          (...as: [combined.UserManagementApiUsersUserIdAboutmePutRequest]) =>
            users.userManagementApiUsersUserIdAboutmePut(...as),
        ),
        R.local((user: combined.ApiUserData) => ({
          apiUserData: user,
        })),
      ),
      getAboutMeSummary: wrapFuncUserId((...as) =>
        summaries.userManagementApiUsersUserIdSummaryGet(...as),
      ),
      sendFcmToken: pipe(
        wrapFuncWithUserId(
          (
            ...as: [
              combined.NotificationsApiSubscriptionsUserIdTokensTokenPutRequest,
            ]
          ) =>
            subscriptions.notificationsApiSubscriptionsUserIdTokensTokenPut(
              ...as,
            ),
        ),
        R.local((token: string) => ({ token })),
      ),
    },
    jobs: {
      getAll: wrapFuncUserId((...as) =>
        jobs.activitiesApiUsersUserIdJobsGet(...as),
      ),
    },
    intl: {
      getUnits: wrapFuncUserId((...as) =>
        units.userManagementApiUsersUserIdSettingsUnitsGet(...as),
      ),
      updateUnits: pipe(
        wrapFuncWithUserId(
          (
            ...as: [
              combined.UserManagementApiUsersUserIdSettingsUnitsPutRequest,
            ]
          ) => units.userManagementApiUsersUserIdSettingsUnitsPut(...as),
        ),
        R.local((unitPreferences: combined.UnitPreferences) => ({
          unitPreferences,
        })),
      ),
    },
    coachTask: {
      getCoachTasks: wrapFuncUserId((...as) =>
        today.coachTasksApiUsersUserIdCoachTasksTodayGet(...as),
      ),
      dismissCoachTask: pipe(
        wrapFuncWithUserId(
          (
            ...as: [
              combined.CoachTasksApiUsersUserIdCoachTasksTodayIdDismissPutRequest,
            ]
          ) => today.coachTasksApiUsersUserIdCoachTasksTodayIdDismissPut(...as),
        ),
        R.local((id: string) => ({
          id,
        })),
      ),
      confirmCoachTask: pipe(
        wrapFuncWithUserId(
          (
            ...as: [
              combined.CoachTasksApiUsersUserIdCoachTasksTodayIdConfirmPutRequest,
            ]
          ) => today.coachTasksApiUsersUserIdCoachTasksTodayIdConfirmPut(...as),
        ),
        R.local((id: string) => ({
          id,
        })),
      ),
    },
    activities: {
      getActivities: wrapFuncUserId((...as) =>
        activities.activitiesApiUsersUserIdActivitiesGet(...as),
      ),
      getPreferences: wrapFuncUserId((...as) =>
        preferences.calendarApiUsersUserIdSportsPreferencesGet(...as),
      ),
      setPreferences: wrapFuncWithUserId(
        (...as: [combined.CalendarApiUsersUserIdSportsPreferencesPutRequest]) =>
          preferences.calendarApiUsersUserIdSportsPreferencesPut(...as),
      ),
      getSports: wrapFuncUserId((...as) =>
        sports.activitiesApiUsersUserIdSportsGet(...as),
      ),
      setSport: wrapFuncWithUserId(
        (...as: [combined.ActivitiesApiUsersUserIdSportsPutRequest]) =>
          sports.activitiesApiUsersUserIdSportsPut(...as),
      ),
      setSportIsLiked: wrapFuncWithUserId(
        (
          ...as: [combined.ActivitiesApiUsersUserIdSportsIdIsLikedAddPutRequest]
        ) => sports.activitiesApiUsersUserIdSportsIdIsLikedAddPut(...as),
      ),
      removeSportIsLiked: wrapFuncWithUserId(
        (
          ...as: [
            combined.ActivitiesApiUsersUserIdSportsIdIsLikedRemovePutRequest,
          ]
        ) => sports.activitiesApiUsersUserIdSportsIdIsLikedRemovePut(...as),
      ),
      getActivitySettings: wrapFuncWithUserId(
        (
          ...as: [
            combined.ActivitiesApiUsersUserIdActivitiesIdSettingsGetRequest,
          ]
        ) => activities.activitiesApiUsersUserIdActivitiesIdSettingsGet(...as),
      ),
      setActivitySettings: wrapFuncWithUserId(
        (
          ...as: [
            combined.ActivitiesApiUsersUserIdActivitiesIdSettingsPutRequest,
          ]
        ) => activities.activitiesApiUsersUserIdActivitiesIdSettingsPut(...as),
      ),
      getMuscleSettings: wrapFuncUserId(
        (...as: [combined.ActivitiesApiUsersUserIdMuscleSettingsGetRequest]) =>
          muscleSettings.activitiesApiUsersUserIdMuscleSettingsGet(...as),
      ),
      setMuscleSettings: wrapFuncWithUserId(
        (...as: [combined.ActivitiesApiUsersUserIdMuscleSettingsPostRequest]) =>
          muscleSettings.activitiesApiUsersUserIdMuscleSettingsPost(...as),
      ),
      getRecommendations: wrapFuncWithUserId(
        flow(
          ({
            start,
            end,
            scheduledMuscles,
            userId,
          }: {
            start: Opt.Option<Time>
            end: Opt.Option<Time>
            scheduledMuscles: Opt.Option<ReadonlyArray<ScheduledMuscle>>
            userId: string
          }) =>
            pipe(
              scheduledMuscles,
              Opt.fold(
                () =>
                  recommendations.calendarApiUsersUserIdCalendarRecommendationsGet(
                    {
                      start: pipe(
                        start,
                        Opt.map(dateAsTime.reverseGet),
                        Opt.toUndefined,
                      ),
                      end: pipe(
                        end,
                        Opt.map(dateAsTime.reverseGet),
                        Opt.toUndefined,
                      ),
                      userId,
                    },
                  ),
                (a) =>
                  recommendations.calendarApiUsersUserIdCalendarRecommendationsPost(
                    {
                      start: pipe(
                        start,
                        Opt.map(dateAsTime.reverseGet),
                        Opt.toUndefined,
                      ),
                      end: pipe(
                        end,
                        Opt.map(dateAsTime.reverseGet),
                        Opt.toUndefined,
                      ),
                      scheduledMuscle: a as Array<ScheduledMuscle>,
                      userId,
                    },
                  ),
              ),
            ),
        ),
      ),
    },
    googleApi: {
      getAccessToken: wrapFuncUserId((...as) =>
        googleApi.userManagementApiUsersUserIdGoogleApiGet(...as),
      ),
      setAuthorisationCode: wrapFuncWithUserId(
        (...as: [combined.UserManagementApiUsersUserIdGoogleApiPutRequest]) =>
          googleApi.userManagementApiUsersUserIdGoogleApiPut(...as),
      ),
    },
    googleFit: {
      get: wrapFuncUserId((...as) =>
        googleFit.googleFitImportApiUsersUserIdSettingsGet(...as),
      ),
      set: wrapFuncWithUserId(
        (...as: [combined.GoogleFitImportApiUsersUserIdSettingsPutRequest]) =>
          googleFit.googleFitImportApiUsersUserIdSettingsPut(...as),
      ),
      delete: wrapFuncUserId((...as) =>
        googleFit.googleFitImportApiUsersUserIdSettingsDelete(...as),
      ),
    },
    referrals: {
      get: wrapFuncUserId((...as) =>
        referrals.userManagementApiUsersUserIdReferralsGet(...as),
      ),
    },
    invites: {
      getAll: wrapFunc(() => invites.userManagementApiInvitesGet()),
      add: wrapFunc(() => invites.userManagementApiInvitesPost()),
    },
    help: {
      getHelp: wrapFuncUserId((...as) =>
        help.userManagementApiUsersUserIdHelpGet(...as),
      ),
      setHelp: pipe(
        wrapFuncWithUserId(
          (...as: [combined.UserManagementApiUsersUserIdHelpPutRequest]) =>
            help.userManagementApiUsersUserIdHelpPut(...as),
        ),
        R.local((help: combined.Help) => ({ help })),
      ),
    },
    workoutVideos: {
      listVideos: wrapFunc(workoutVideos.listVideos),
    },
  }
}
export default api
export type API = ReturnType<typeof api>
