/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExerciseSlotFromJSON, ExerciseSlotToJSON, } from "./ExerciseSlot";
/**
 * Check if a given object implements the Sport interface.
 */
export function instanceOfSport(value) {
    if (!("id" in value) || value.id === undefined)
        return false;
    if (!("isLiked" in value) || value.isLiked === undefined)
        return false;
    if (!("frequency" in value) || value.frequency === undefined)
        return false;
    if (!("fixedSchedule" in value) || value.fixedSchedule === undefined)
        return false;
    if (!("defaultIntensity" in value) || value.defaultIntensity === undefined)
        return false;
    return true;
}
export function SportFromJSON(json) {
    return SportFromJSONTyped(json, false);
}
export function SportFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        id: json.id,
        name: json.name == null ? undefined : json.name,
        instructions: json.instructions == null ? undefined : json.instructions,
        isLiked: json.isLiked,
        frequency: json.frequency,
        fixedSchedule: json.fixedSchedule,
        exerciseSlots: json.exerciseSlots == null
            ? undefined
            : json.exerciseSlots.map(ExerciseSlotFromJSON),
        workoutMethod: json.workoutMethod == null ? undefined : json.workoutMethod,
        workoutTarget: json.workoutTarget == null ? undefined : json.workoutTarget,
        defaultIntensity: json.defaultIntensity,
    };
}
export function SportToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        id: value.id,
        name: value.name,
        instructions: value.instructions,
        isLiked: value.isLiked,
        frequency: value.frequency,
        fixedSchedule: value.fixedSchedule,
        exerciseSlots: value.exerciseSlots == null
            ? undefined
            : value.exerciseSlots.map(ExerciseSlotToJSON),
        workoutMethod: value.workoutMethod,
        workoutTarget: value.workoutTarget,
        defaultIntensity: value.defaultIntensity,
    };
}
