/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * The source / platform a video can be found on.
 * @export
 */
export const VideoSource = {
    Youtube: "youtube",
    Vimeo: "vimeo",
};
export function instanceOfVideoSource(value) {
    for (const key in VideoSource) {
        if (Object.prototype.hasOwnProperty.call(VideoSource, key)) {
            if (VideoSource[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function VideoSourceFromJSON(json) {
    return VideoSourceFromJSONTyped(json, false);
}
export function VideoSourceFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function VideoSourceToJSON(value) {
    return value;
}
