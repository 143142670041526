/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityFromJSONTyped, ActivityToJSON, } from "./Activity";
import { ActivityTypeFromJSON, ActivityTypeToJSON, } from "./ActivityType";
import { MuscleShareFromJSON, MuscleShareToJSON, } from "./MuscleShare";
/**
 * Check if a given object implements the ActivityJob interface.
 */
export function instanceOfActivityJob(value) {
    if (!("$type" in value) || value.$type === undefined)
        return false;
    return true;
}
export function ActivityJobFromJSON(json) {
    return ActivityJobFromJSONTyped(json, false);
}
export function ActivityJobFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        ...ActivityFromJSONTyped(json, ignoreDiscriminator),
        $type: ActivityTypeFromJSON(json.$type),
        muscleShares: json.muscleShares == null
            ? undefined
            : json.muscleShares.map(MuscleShareFromJSON),
    };
}
export function ActivityJobToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        ...ActivityToJSON(value, true),
        $type: ActivityTypeToJSON(value.$type),
        muscleShares: value.muscleShares == null
            ? undefined
            : value.muscleShares.map(MuscleShareToJSON),
    };
}
