/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const BodyType = {
    Skinny: "skinny",
    Weak: "weak",
    Average: "average",
    Athletic: "athletic",
    Strong: "strong",
    Sportive: "sportive",
    Corpulent: "corpulent",
    Overweight: "overweight",
    Obese: "obese",
};
export function instanceOfBodyType(value) {
    for (const key in BodyType) {
        if (Object.prototype.hasOwnProperty.call(BodyType, key)) {
            if (BodyType[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function BodyTypeFromJSON(json) {
    return BodyTypeFromJSONTyped(json, false);
}
export function BodyTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function BodyTypeToJSON(value) {
    return value;
}
