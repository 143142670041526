// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file activity/v1/activity_category.proto (package activity.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum activity.v1.ActivityCategory
 */
export enum ActivityCategory {
  /**
   * @generated from enum value: ACTIVITY_CATEGORY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * repetitions (+ weight)
   *
   * @generated from enum value: ACTIVITY_CATEGORY_REPS_BODY_WEIGHT = 1;
   */
  REPS_BODY_WEIGHT = 1,

  /**
   * repetitions + weight
   *
   * @generated from enum value: ACTIVITY_CATEGORY_REPS_EXTRA_WEIGHT = 2;
   */
  REPS_EXTRA_WEIGHT = 2,

  /**
   * duration (+ weight)
   *
   * @generated from enum value: ACTIVITY_CATEGORY_ISOMETRIC = 3;
   */
  ISOMETRIC = 3,

  /**
   * duration
   *
   * @generated from enum value: ACTIVITY_CATEGORY_GENERIC = 4;
   */
  GENERIC = 4,

  /**
   * distance + duration
   *
   * @generated from enum value: ACTIVITY_CATEGORY_DISTANCE = 5;
   */
  DISTANCE = 5,

  /**
   * none
   *
   * @generated from enum value: ACTIVITY_CATEGORY_STRETCHING = 6;
   */
  STRETCHING = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(ActivityCategory)
proto3.util.setEnumType(ActivityCategory, "activity.v1.ActivityCategory", [
  { no: 0, name: "ACTIVITY_CATEGORY_UNSPECIFIED" },
  { no: 1, name: "ACTIVITY_CATEGORY_REPS_BODY_WEIGHT" },
  { no: 2, name: "ACTIVITY_CATEGORY_REPS_EXTRA_WEIGHT" },
  { no: 3, name: "ACTIVITY_CATEGORY_ISOMETRIC" },
  { no: 4, name: "ACTIVITY_CATEGORY_GENERIC" },
  { no: 5, name: "ACTIVITY_CATEGORY_DISTANCE" },
  { no: 6, name: "ACTIVITY_CATEGORY_STRETCHING" },
]);

