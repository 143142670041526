/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the UserSleepSchedule interface.
 */
export function instanceOfUserSleepSchedule(value) {
    return true;
}
export function UserSleepScheduleFromJSON(json) {
    return UserSleepScheduleFromJSONTyped(json, false);
}
export function UserSleepScheduleFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        none: json.none == null ? undefined : json.none,
        monday: json.monday == null ? undefined : json.monday,
        tuesday: json.tuesday == null ? undefined : json.tuesday,
        wednesday: json.wednesday == null ? undefined : json.wednesday,
        thursday: json.thursday == null ? undefined : json.thursday,
        friday: json.friday == null ? undefined : json.friday,
        saturday: json.saturday == null ? undefined : json.saturday,
        sunday: json.sunday == null ? undefined : json.sunday,
    };
}
export function UserSleepScheduleToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        none: value.none,
        monday: value.monday,
        tuesday: value.tuesday,
        wednesday: value.wednesday,
        thursday: value.thursday,
        friday: value.friday,
        saturday: value.saturday,
        sunday: value.sunday,
    };
}
