/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the MuscleStatus interface.
 */
export function instanceOfMuscleStatus(value) {
    if (!("readiness" in value) || value.readiness === undefined)
        return false;
    if (!("condition" in value) || value.condition === undefined)
        return false;
    return true;
}
export function MuscleStatusFromJSON(json) {
    return MuscleStatusFromJSONTyped(json, false);
}
export function MuscleStatusFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        readiness: json.readiness,
        condition: json.condition,
        recoveryTime: json.recoveryTime == null ? undefined : json.recoveryTime,
        regenerationSlope: json.regenerationSlope == null ? undefined : json.regenerationSlope,
    };
}
export function MuscleStatusToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        readiness: value.readiness,
        condition: value.condition,
        recoveryTime: value.recoveryTime,
        regenerationSlope: value.regenerationSlope,
    };
}
