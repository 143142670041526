/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Option1UnitDistanceFromJSON, Option1UnitDistanceToJSON, } from "./Option1UnitDistance";
import { Option1UnitHeightFromJSON, Option1UnitHeightToJSON, } from "./Option1UnitHeight";
import { Option1UnitMassFromJSON, Option1UnitMassToJSON, } from "./Option1UnitMass";
/**
 * Check if a given object implements the UnitOptions interface.
 */
export function instanceOfUnitOptions(value) {
    if (!("height" in value) || value.height === undefined)
        return false;
    if (!("mass" in value) || value.mass === undefined)
        return false;
    if (!("distance" in value) || value.distance === undefined)
        return false;
    return true;
}
export function UnitOptionsFromJSON(json) {
    return UnitOptionsFromJSONTyped(json, false);
}
export function UnitOptionsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        height: json.height.map(Option1UnitHeightFromJSON),
        mass: json.mass.map(Option1UnitMassFromJSON),
        distance: json.distance.map(Option1UnitDistanceFromJSON),
    };
}
export function UnitOptionsToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        height: value.height.map(Option1UnitHeightToJSON),
        mass: value.mass.map(Option1UnitMassToJSON),
        distance: value.distance.map(Option1UnitDistanceToJSON),
    };
}
