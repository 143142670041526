/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityInstanceFromJSONTyped, ActivityInstanceToJSON, } from "./ActivityInstance";
import { ActivityMusclesFromJSON, ActivityMusclesToJSON, } from "./ActivityMuscles";
import { ActivityTypeFromJSON, ActivityTypeToJSON, } from "./ActivityType";
/**
 * Check if a given object implements the ActivityInstanceMuscles interface.
 */
export function instanceOfActivityInstanceMuscles(value) {
    if (!("activity" in value) || value.activity === undefined)
        return false;
    if (!("muscleGroupIds" in value) || value.muscleGroupIds === undefined)
        return false;
    if (!("$type" in value) || value.$type === undefined)
        return false;
    return true;
}
export function ActivityInstanceMusclesFromJSON(json) {
    return ActivityInstanceMusclesFromJSONTyped(json, false);
}
export function ActivityInstanceMusclesFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        ...ActivityInstanceFromJSONTyped(json, ignoreDiscriminator),
        activity: ActivityMusclesFromJSON(json.activity),
        muscleGroupIds: json.muscleGroupIds,
        $type: ActivityTypeFromJSON(json.$type),
        duration: json.duration == null ? undefined : json.duration,
        intensity: json.intensity == null ? undefined : json.intensity,
        alignment: json.alignment == null ? undefined : json.alignment,
    };
}
export function ActivityInstanceMusclesToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        ...ActivityInstanceToJSON(value, true),
        activity: ActivityMusclesToJSON(value.activity),
        muscleGroupIds: value.muscleGroupIds,
        $type: ActivityTypeToJSON(value.$type),
        duration: value.duration,
        intensity: value.intensity,
        alignment: value.alignment,
    };
}
