/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HabitActivityFromJSON, HabitActivityToJSON, } from "./HabitActivity";
import { HabitAddictionFromJSON, HabitAddictionToJSON, } from "./HabitAddiction";
import { HabitFoodFromJSON, HabitFoodToJSON, } from "./HabitFood";
/**
 * Check if a given object implements the UserHabits interface.
 */
export function instanceOfUserHabits(value) {
    if (!("activityLevel" in value) || value.activityLevel === undefined)
        return false;
    if (!("drinkingWater" in value) || value.drinkingWater === undefined)
        return false;
    if (!("drinkingAlcohol" in value) || value.drinkingAlcohol === undefined)
        return false;
    if (!("smoking" in value) || value.smoking === undefined)
        return false;
    if (!("eating" in value) || value.eating === undefined)
        return false;
    return true;
}
export function UserHabitsFromJSON(json) {
    return UserHabitsFromJSONTyped(json, false);
}
export function UserHabitsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        activityLevel: HabitActivityFromJSON(json.activityLevel),
        drinkingWater: HabitFoodFromJSON(json.drinkingWater),
        drinkingAlcohol: HabitAddictionFromJSON(json.drinkingAlcohol),
        smoking: HabitAddictionFromJSON(json.smoking),
        eating: HabitFoodFromJSON(json.eating),
    };
}
export function UserHabitsToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        activityLevel: HabitActivityToJSON(value.activityLevel),
        drinkingWater: HabitFoodToJSON(value.drinkingWater),
        drinkingAlcohol: HabitAddictionToJSON(value.drinkingAlcohol),
        smoking: HabitAddictionToJSON(value.smoking),
        eating: HabitFoodToJSON(value.eating),
    };
}
