/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ActivityMuscleInfo interface.
 */
export function instanceOfActivityMuscleInfo(value) {
    if (!("alignment" in value) || value.alignment === undefined)
        return false;
    if (!("workload" in value) || value.workload === undefined)
        return false;
    return true;
}
export function ActivityMuscleInfoFromJSON(json) {
    return ActivityMuscleInfoFromJSONTyped(json, false);
}
export function ActivityMuscleInfoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        alignment: json.alignment,
        workload: json.workload,
    };
}
export function ActivityMuscleInfoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        alignment: value.alignment,
        workload: value.workload,
    };
}
