/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UnitDistanceFromJSON, UnitDistanceToJSON, } from "./UnitDistance";
import { UnitHeightFromJSON, UnitHeightToJSON, } from "./UnitHeight";
import { UnitMassFromJSON, UnitMassToJSON, } from "./UnitMass";
/**
 * Check if a given object implements the UnitsOverride interface.
 */
export function instanceOfUnitsOverride(value) {
    return true;
}
export function UnitsOverrideFromJSON(json) {
    return UnitsOverrideFromJSONTyped(json, false);
}
export function UnitsOverrideFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        distance: json.distance == null ? undefined : UnitDistanceFromJSON(json.distance),
        height: json.height == null ? undefined : UnitHeightFromJSON(json.height),
        mass: json.mass == null ? undefined : UnitMassFromJSON(json.mass),
    };
}
export function UnitsOverrideToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        distance: UnitDistanceToJSON(value.distance),
        height: UnitHeightToJSON(value.height),
        mass: UnitMassToJSON(value.mass),
    };
}
