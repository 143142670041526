/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityInstancesResponseFromJSON, EventFromJSON, RecommendationRequestToJSON, ScheduledMuscleToJSON, } from "../models/index";
import * as runtime from "../runtime";
/**
 *
 */
export class RecommendationsApi extends runtime.BaseAPI {
    /**
     */
    async activitiesApiUsersUserIdRecommendationsPostRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling activitiesApiUsersUserIdRecommendationsPost().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/users/{userId}/recommendations`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: RecommendationRequestToJSON(requestParameters.recommendationRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityInstancesResponseFromJSON(jsonValue));
    }
    /**
     */
    async activitiesApiUsersUserIdRecommendationsPost(requestParameters, initOverrides) {
        const response = await this.activitiesApiUsersUserIdRecommendationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async calendarApiUsersUserIdCalendarRecommendationsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling calendarApiUsersUserIdCalendarRecommendationsGet().');
        }
        const queryParameters = {};
        if (requestParameters.start != null) {
            queryParameters.start = requestParameters.start.toISOString();
        }
        if (requestParameters.end != null) {
            queryParameters.end = requestParameters.end.toISOString();
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calendar/api/users/{userId}/calendar/recommendations`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EventFromJSON));
    }
    /**
     */
    async calendarApiUsersUserIdCalendarRecommendationsGet(requestParameters, initOverrides) {
        const response = await this.calendarApiUsersUserIdCalendarRecommendationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async calendarApiUsersUserIdCalendarRecommendationsPostRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling calendarApiUsersUserIdCalendarRecommendationsPost().');
        }
        const queryParameters = {};
        if (requestParameters.start != null) {
            queryParameters.start = requestParameters.start.toISOString();
        }
        if (requestParameters.end != null) {
            queryParameters.end = requestParameters.end.toISOString();
        }
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calendar/api/users/{userId}/calendar/recommendations`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.scheduledMuscle.map(ScheduledMuscleToJSON),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EventFromJSON));
    }
    /**
     */
    async calendarApiUsersUserIdCalendarRecommendationsPost(requestParameters, initOverrides) {
        const response = await this.calendarApiUsersUserIdCalendarRecommendationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
