/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LoginResponseFromJSON, UserAccountToJSON, } from "../models/index";
import * as runtime from "../runtime";
/**
 *
 */
export class UserRegisterApi extends runtime.BaseAPI {
    /**
     * Handles POST requests for UserManagement.Controllers.UserRegisterController.
     */
    async userManagementApiUsersRegisterPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/register`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: UserAccountToJSON(requestParameters.userAccount),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }
    /**
     * Handles POST requests for UserManagement.Controllers.UserRegisterController.
     */
    async userManagementApiUsersRegisterPost(requestParameters = {}, initOverrides) {
        const response = await this.userManagementApiUsersRegisterPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
