/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityFromJSONTyped, ActivityToJSON, } from "./Activity";
import { ActivityCategoryFromJSON, ActivityCategoryToJSON, } from "./ActivityCategory";
import { ActivityTagFromJSON, ActivityTagToJSON, } from "./ActivityTag";
import { ActivityTypeFromJSON, ActivityTypeToJSON, } from "./ActivityType";
import { EquipmentFromJSON, EquipmentToJSON, } from "./Equipment";
import { MuscleShareFromJSON, MuscleShareToJSON, } from "./MuscleShare";
/**
 * Check if a given object implements the ActivityExercise interface.
 */
export function instanceOfActivityExercise(value) {
    if (!("$type" in value) || value.$type === undefined)
        return false;
    if (!("category" in value) || value.category === undefined)
        return false;
    if (!("tags" in value) || value.tags === undefined)
        return false;
    return true;
}
export function ActivityExerciseFromJSON(json) {
    return ActivityExerciseFromJSONTyped(json, false);
}
export function ActivityExerciseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        ...ActivityFromJSONTyped(json, ignoreDiscriminator),
        $type: ActivityTypeFromJSON(json.$type),
        category: ActivityCategoryFromJSON(json.category),
        tags: json.tags.map(ActivityTagFromJSON),
        muscleShares: json.muscleShares == null
            ? undefined
            : json.muscleShares.map(MuscleShareFromJSON),
        equipments: json.equipments == null
            ? undefined
            : json.equipments.map(EquipmentFromJSON),
        isLiked: json.isLiked == null ? undefined : json.isLiked,
        isDenylisted: json.isDenylisted == null ? undefined : json.isDenylisted,
        lastInstance: json.lastInstance == null ? undefined : new Date(json.lastInstance),
        frequency: json.frequency == null ? undefined : json.frequency,
    };
}
export function ActivityExerciseToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        ...ActivityToJSON(value, true),
        $type: ActivityTypeToJSON(value.$type),
        category: ActivityCategoryToJSON(value.category),
        tags: value.tags.map(ActivityTagToJSON),
        muscleShares: value.muscleShares == null
            ? undefined
            : value.muscleShares.map(MuscleShareToJSON),
        equipments: value.equipments == null
            ? undefined
            : value.equipments.map(EquipmentToJSON),
        isLiked: value.isLiked,
        isDenylisted: value.isDenylisted,
        lastInstance: value.lastInstance == null
            ? undefined
            : value.lastInstance.toISOString(),
        frequency: value.frequency,
    };
}
