/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GeneralSportsPreferences interface.
 */
export function instanceOfGeneralSportsPreferences(value) {
    if (!("enabled" in value) || value.enabled === undefined)
        return false;
    if (!("maxSportsDays" in value) || value.maxSportsDays === undefined)
        return false;
    if (!("maxCardioDays" in value) || value.maxCardioDays === undefined)
        return false;
    return true;
}
export function GeneralSportsPreferencesFromJSON(json) {
    return GeneralSportsPreferencesFromJSONTyped(json, false);
}
export function GeneralSportsPreferencesFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        enabled: json.enabled,
        maxSportsDays: json.maxSportsDays,
        maxCardioDays: json.maxCardioDays,
    };
}
export function GeneralSportsPreferencesToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        enabled: value.enabled,
        maxSportsDays: value.maxSportsDays,
        maxCardioDays: value.maxCardioDays,
    };
}
