// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file activity/v1/activity_stats_summary.proto (package activity.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * Summary of activity stats for a user.
 *
 * @generated from message activity.v1.ActivityStatsSummary
 */
export class ActivityStatsSummary extends Message<ActivityStatsSummary> {
  /**
   * The last time this activity was performed by the user.
   *
   * @generated from field: google.protobuf.Timestamp last_instance = 1;
   */
  lastInstance?: Timestamp;

  /**
   * How often the activity was performed by the user over the last 3 months.
   *
   * @generated from field: int32 frequency_3_months = 2;
   */
  frequency3Months = 0;

  constructor(data?: PartialMessage<ActivityStatsSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "activity.v1.ActivityStatsSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "last_instance", kind: "message", T: Timestamp },
    { no: 2, name: "frequency_3_months", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivityStatsSummary {
    return new ActivityStatsSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivityStatsSummary {
    return new ActivityStatsSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivityStatsSummary {
    return new ActivityStatsSummary().fromJsonString(jsonString, options);
  }

  static equals(a: ActivityStatsSummary | PlainMessage<ActivityStatsSummary> | undefined, b: ActivityStatsSummary | PlainMessage<ActivityStatsSummary> | undefined): boolean {
    return proto3.util.equals(ActivityStatsSummary, a, b);
  }
}

