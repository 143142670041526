import * as Opt from "fp-ts/es6/Option";
import { Getter, Iso, Lens, Prism } from "monocle-ts";
import { ioTsPrism } from "../internal";
import { localDuration } from "../Local";
import { isoLocalDuration } from "../Local/Duration";
import { Temporal } from "@js-temporal/polyfill";
import * as t from "io-ts";
export const _timeZone = new Lens((s) => s.getTimeZone(), (timeZone) => (s) => s.withTimeZone(timeZone));
export const _value = new Lens((s) => s.toPlainDateTime(), (a) => (s) => a.toZonedDateTime(s.timeZoneId));
export const mkZonedLocalTime = (timeZone) => (value) => value.toZonedDateTime(timeZone);
export const mkZonedTime = (timeZone) => (value) => value.toZonedDateTimeISO(timeZone);
export const zonedTime = {
    equals: (a, b) => a.equals(b),
    compare: (a, b) => Temporal.ZonedDateTime.compare(a, b),
};
export const equals = (d2) => (d) => zonedTime.equals(d, d2);
export const compare = (d2) => (d) => zonedTime.compare(d, d2);
export const localTimeAsZonedTime = new Iso(([value, timeZone]) => value.toZonedDateTime(timeZone), (s) => [s.toPlainDateTime(), s.getTimeZone()]);
export const zonedTimeToLocalTime = new Getter((s) => s.toPlainDateTime());
export const timeAsZonedTime = new Iso(([value, timeZone]) => value.toZonedDateTimeISO(timeZone), (s) => [s.toInstant(), s.getTimeZone()]);
export const zonedTimeToTime = new Getter((s) => s.toInstant());
export const localTimeInZone = (timeZone) => new Prism((s) => 
// @TODO Is there a better way to test tzs for equality?
s.timeZoneId === timeZone.id ? Opt.some(s.toPlainDateTime()) : Opt.none, (s) => s.toZonedDateTime(timeZone));
export const timeInZone = (timeZone) => new Prism((s) => 
// @TODO Is there a better way to test tzs for equality?
s.timeZoneId === timeZone.id ? Opt.some(s.toInstant()) : Opt.none, (s) => s.toZonedDateTimeISO(timeZone));
export const zonedNow = (timeZone) => () => Temporal.Now.zonedDateTimeISO(timeZone);
export const zonedNowInCurrentTimeZone = () => Temporal.Now.zonedDateTimeISO();
export const stringAsZonedTime = new Prism((s) => Opt.tryCatch(() => Temporal.ZonedDateTime.from(s, { overflow: "reject" })), (s) => s.toString());
export const zonedTimeT = t.string.pipe(ioTsPrism("ZonedTime", (a) => a instanceof Temporal.ZonedDateTime, stringAsZonedTime));
export const dayOfZonedTime = new Lens((s) => s.toPlainDate(), (a) => (s) => s.withPlainDate(a));
export const zonedDuration = {
    ...localDuration,
    add: (t, d) => t.add(isoLocalDuration.get(d)),
    difference: (t, t2) => isoLocalDuration.reverseGet(t.until(t2)),
};
