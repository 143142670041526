import { FirebaseReducer, firebaseReducer } from "react-18-redux-firebase"
import {
  loadingBarMiddleware,
  loadingBarReducer,
} from "react-redux-loading-bar"

import * as E from "fp-ts/es6/Either"
import * as Func from "fp-ts/es6/function"
import { flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Rec from "fp-ts/es6/ReadonlyRecord"

import * as activity from "@fitnesspilot/data-activity/dist/store"
import * as api from "@fitnesspilot/data-api"
import * as coachTask from "@fitnesspilot/data-coach-task/dist/store"
import * as common from "@fitnesspilot/data-common/dist/store"
import {
  isAnyErrorAction,
  isPayloadAction,
} from "@fitnesspilot/data-common/dist/store"
import * as event from "@fitnesspilot/data-event/dist/store"
import * as help from "@fitnesspilot/data-help/dist/store"
import * as legal from "@fitnesspilot/data-legal"
import * as user from "@fitnesspilot/data-user"

import * as config from "./config"
import * as clientsWeb from "./store/."

import GoogleTagManager from "@redux-beacon/google-tag-manager"
import * as Sentry from "@sentry/react"
import { createBrowserHistory } from "history"
import * as storage from "localforage"
import {
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
  Reducer,
  Store,
  StoreEnhancer,
} from "redux"
import { createMiddleware as createBeacon } from "redux-beacon"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"
import { createReduxHistoryContext, RouterState } from "redux-first-history"
import { createLogger } from "redux-logger"
import { combineEpics, createEpicMiddleware, Epic } from "redux-observable"
import persistReducer, { PersistPartial } from "redux-persist/es/persistReducer"

/// NOTE: some types cannot be persisted, e.g. class instances
// such as time-ts's `Time`.
export type RootState = {
  [activity.stateKey]: activity.State
  [api.stateKey]: api.State
  [clientsWeb.stateKey]: clientsWeb.State
  [coachTask.stateKey]: coachTask.State & PersistPartial
  [common.stateKey]: common.State
  [event.stateKey]: event.State
  [help.stateKey]: help.State
  [legal.stateKey]: legal.State & PersistPartial
  [user.stateKey]: user.State & PersistPartial
  loadingBar: any
  firebase: FirebaseReducer.Reducer
  router: RouterState
}

export type RootAction =
  | activity.Action
  | api.Action
  | clientsWeb.Action
  | coachTask.Action
  | common.Action
  | event.Action
  | help.Action
  | legal.Action
  | user.Action

type RootEpic = Epic<RootAction, RootAction, RootState, any>
const rootEpic = combineEpics(
  api.epic as RootEpic,
  user.epic as RootEpic,
  common.epic as RootEpic,
  legal.epic as RootEpic,
  activity.epic as RootEpic,
  event.epic as RootEpic,
  help.epic as RootEpic,
  coachTask.epic as RootEpic,
  clientsWeb.epic as RootEpic,
)

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() })

type RootReducer = Reducer<RootState, RootAction>
const asKeys = <a extends Record<string, any>>(ks: Array<keyof a>) => ks
const rootReducer: RootReducer = combineReducers({
  [activity.stateKey]: activity.reducer,
  [api.stateKey]: api.reducer,
  [clientsWeb.stateKey]: clientsWeb.reducer,
  [coachTask.stateKey]: persistReducer(
    { key: "coachTasks", storage },
    coachTask.reducer,
  ),
  [common.stateKey]: common.reducer,
  [event.stateKey]: event.reducer,
  [help.stateKey]: help.reducer,
  [legal.stateKey]: persistReducer({ key: "legal", storage }, legal.reducer),
  [user.stateKey]: persistReducer(
    {
      key: "user",
      storage,
      // HINT: user & profilePhoto require contain types tha cannot be serialized
      whitelist: ["isLoggedIn", "fcmToken"],
    },
    user.reducer,
  ),
  loadingBar: loadingBarReducer,
  firebase: firebaseReducer,
  router: routerReducer,
})

const removePasswords = flow(
  E.fromPredicate(
    (v: any): v is Record<any, any> => typeof v === "object",
    Func.identity,
  ),
  E.map(
    Rec.filterMapWithIndex((k, v) =>
      k === "password" ? Opt.none : Opt.some(v),
    ),
  ),
  E.fold(Func.identity, Func.identity),
)
const mkStore = (): Store<RootState, RootAction> => {
  const epic = createEpicMiddleware<RootAction, RootAction, RootState>()

  const loadingBar = loadingBarMiddleware({
    promiseTypeSuffixes: ["/request", "/success", "/failure"],
  })

  const gtm = GoogleTagManager()
  const beacon: Middleware<RootAction, RootState> = (store) =>
    pipe(
      store.getState()[legal.stateKey].cookieConsent.acceptedCookies,
      (allowedCookies) =>
        allowedCookies.includes(legal.CookieId.gtm)
          ? createBeacon(
              {
                "*": (action) => ({
                  category: "redux",
                  action: action.type,
                  payload: removePasswords(action.payload),
                }),
              },
              gtm,
            )(store)
          : (next) => (action) => next(action),
    )

  const sentryEnhancer = Sentry.createReduxEnhancer({
    // log error actions
    actionTransformer: (action) =>
      isPayloadAction(action) && isAnyErrorAction(action) ? action : null,
    stateTransformer: () => null,
  })

  const store = createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware as Middleware<any, RootState, any>,
        epic,
        loadingBar as Middleware<any, RootState, any>,
        beacon,
        ...(config.env === "development"
          ? [createLogger({ collapsed: true })]
          : []),
      ),
      sentryEnhancer as StoreEnhancer<
        { dispatch: unknown },
        Record<string, unknown>
      >,
    ),
  )

  epic.run(rootEpic)

  return store
}

export const store = mkStore()

export const history = createReduxHistory(store)
